import { EMPTY_SIGN, formatFullName, formatText } from 'helpers/format'
import { useBusinessPartnersInfiniteQuery, useLeadContributorsInfiniteQuery } from 'helpers/queries'
import { CLICKS } from 'helpers/tracking'
import { useDebouncedState } from 'hooks/debouncedState'
import React, { useCallback, useMemo } from 'react'

import { DropdownOptionValue } from 'ui/dropdowns/dropdown/types'
import { SelectInput } from 'ui/dropdowns/selectInput'

type LeadContributorsSelectProps = {
  value: DropdownOptionValue
  setValue: (value: DropdownOptionValue) => void
  setLeadContributorIds: (leadContributorIds: null | string[]) => void
}

const selectAllOption = {
  label: 'Tous les apporteurs',
  value: null,
  type: 'text' as const,
  data: null,
}

export const LeadContributorsSelect: React.FC<LeadContributorsSelectProps> = ({
  value,
  setValue,
  setLeadContributorIds,
}) => {
  const [search, setSearch] = useDebouncedState('', 200)
  const leadContributorsQuery = useLeadContributorsInfiniteQuery(search)
  const businessPartnersQuery = useBusinessPartnersInfiniteQuery(search)

  const leadContributorOptions = useMemo(
    () =>
      leadContributorsQuery?.data?.pages
        .flatMap(page => page.items)
        .map(leadContributor => ({
          label: formatFullName(
            leadContributor.firstName,
            leadContributor.lastName,
            leadContributor.email ?? EMPTY_SIGN
          ),
          value: leadContributor.id,
          type: 'text' as const,
          data: leadContributor,
        })) ?? [],
    [leadContributorsQuery]
  )

  const businessPartnerOptions = useMemo(
    () =>
      businessPartnersQuery?.data?.pages
        .flatMap(page => page.items)
        .map(businessPartner => ({
          label: formatText(businessPartner.name),
          value: businessPartner.id,
          type: 'text' as const,
          data: businessPartner,
        })) ?? [],
    [businessPartnersQuery]
  )

  const fetchNextPage = useCallback(() => {
    if (businessPartnersQuery.hasNextPage) {
      businessPartnersQuery.fetchNextPage()
    } else {
      leadContributorsQuery.fetchNextPage()
    }
  }, [businessPartnersQuery, leadContributorsQuery])

  const selectOnChange = useCallback(
    (value: DropdownOptionValue, data?: unknown) => {
      setValue(value)

      // no filter
      if (!value) {
        return setLeadContributorIds(null)
      }

      // filter by business partner
      if (data && typeof data === 'object' && 'leadContributorIds' in data) {
        // Necessary type cast because 'data' is of type 'unknown'
        const typedData = data as { leadContributorIds: string[] }
        return setLeadContributorIds(typedData.leadContributorIds)
      }

      // filter by lead contributor
      setLeadContributorIds([value])
    },
    [setValue, setLeadContributorIds]
  )

  return (
    <SelectInput
      className="selectInput--small"
      buttonClassName="w-[200px]"
      dropdownClassName="w-[240px] !z-50"
      value={value}
      onChange={selectOnChange}
      options={[[selectAllOption], businessPartnerOptions, leadContributorOptions]}
      isSearchable={true}
      tracking={[CLICKS.opportunitiesFilter, { filter: 'lead-contributor' }]}
      onDropdownBottomReached={fetchNextPage}
      onSearch={setSearch}
    />
  )
}
