import { useForm } from '@tanstack/react-form'
import { TanstackFormInput } from 'ui/misc/tanstackFormInput'
import { textInputProps } from 'helpers/tanstackForm'
import classNames from 'classnames'
import {
  LEAD_CONTRIBUTOR_BUSINESS_TYPES,
  LeadContributor,
  LeadContributorErrorAttribute,
  LeadContributorErrorType,
} from 'lib/oleenApi'
import React, { PropsWithChildren, useState } from 'react'
import { t } from 'utils/i18n'

import { RadioGroup } from 'ui/buttons/radioGroup'
import { TextInput } from 'ui/inputs/textInput'
import { SelectInput } from 'ui/dropdowns/selectInput'
import { PhoneInput } from 'ui/inputs/phoneInput'
import { EnvelopeIcon } from '@heroicons/react/20/solid'
import { BusinessPartnerSelectInput } from 'components/businessPartners/businessPartnerSelectInput'

enum Fields {
  representativeId = 'representativeId',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  jobTitle = 'jobTitle',
  businessType = 'businessType',
  businessPartnerId = 'businessPartnerId',
}

export type FormData = {
  [key in Fields]: string | null
}

const businessTypesOptions = LEAD_CONTRIBUTOR_BUSINESS_TYPES.map(value => ({
  value,
  label: t(`business_partners.business_type.${value}`),
  type: 'text' as const,
}))

export type FormError = Partial<Record<LeadContributorErrorAttribute, LeadContributorErrorType>>

export type FormProps = PropsWithChildren<{
  leadContributor?: LeadContributor
  error?: FormError
  onSubmit: (formData: FormData) => void
}>

export const Form: React.FC<FormProps> = ({ leadContributor, onSubmit, error, children }) => {
  const [hasBusinessPartner, setHasBusinessPartner] = useState(!!leadContributor?.businessPartnerId)

  const { Field, handleSubmit } = useForm({
    defaultValues: {
      [Fields.firstName]: leadContributor?.firstName ?? null,
      [Fields.lastName]: leadContributor?.lastName ?? null,
      [Fields.email]: leadContributor?.email ?? null,
      [Fields.phone]: leadContributor?.phone ?? null,
      [Fields.jobTitle]: leadContributor?.jobTitle ?? null,
      [Fields.businessType]: !hasBusinessPartner ? (leadContributor?.businessType as string | undefined) : null,
      [Fields.businessPartnerId]: hasBusinessPartner ? leadContributor?.businessPartnerId : null,
    },
    onSubmit: ({ value }) => onSubmit(value as FormData),
  })

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        handleSubmit()
      }}
    >
      <Field name={Fields.firstName}>
        {field => (
          <TanstackFormInput
            className="w-full"
            label={t('lead_contributors.form.first_name')}
            field={field}
            apiError={error?.first_name}
          >
            <TextInput placeholder={'Ex: John'} {...textInputProps(field, error)} />
          </TanstackFormInput>
        )}
      </Field>
      <Field name={Fields.lastName}>
        {field => (
          <TanstackFormInput
            className="w-full"
            label={t('lead_contributors.form.last_name')}
            field={field}
            apiError={error?.last_name}
          >
            <TextInput placeholder={'Ex: Doe'} {...textInputProps(field, error)} />
          </TanstackFormInput>
        )}
      </Field>
      <Field name={Fields.email}>
        {field => (
          <TanstackFormInput
            className="w-full"
            label={t('lead_contributors.form.email')}
            field={field}
            apiError={error?.email}
          >
            <TextInput Icon={EnvelopeIcon} placeholder={'Ex: email@apporteur.com'} {...textInputProps(field, error)} />
          </TanstackFormInput>
        )}
      </Field>
      <Field name={Fields.phone}>
        {field => (
          <TanstackFormInput
            className="w-full"
            label={t('lead_contributors.form.phone')}
            field={field}
            apiError={error?.phone}
          >
            <PhoneInput {...textInputProps(field, error)} />
          </TanstackFormInput>
        )}
      </Field>
      <Field name={Fields.jobTitle}>
        {field => (
          <TanstackFormInput
            className="w-full"
            label={t('lead_contributors.form.job_title')}
            field={field}
            apiError={error?.job_title}
          >
            <TextInput placeholder={'Ex: Directeur'} {...textInputProps(field, error)} />
          </TanstackFormInput>
        )}
      </Field>
      <p className="font-inter text-xs font-semibold leading-4 antialiased">
        {t('lead_contributors.form.has_business_partner')}
      </p>
      <RadioGroup
        className="flex gap-6"
        name="hasBusinessPartner"
        value={hasBusinessPartner.toString()}
        onChange={value => setHasBusinessPartner(value === 'true')}
        options={[
          { value: 'true', label: t('common.yes') },
          { value: 'false', label: t('common.no') },
        ]}
      />
      {!hasBusinessPartner && (
        <Field name={Fields.businessType}>
          {field => (
            <TanstackFormInput
              className={classNames({ 'invisible absolute': hasBusinessPartner })}
              label={t('lead_contributors.form.business_type')}
              field={field}
              apiError={error?.business_type}
            >
              <SelectInput
                value={hasBusinessPartner ? null : field.state.value}
                options={[businessTypesOptions]}
                onChange={value => field.handleChange(value)}
                onBlur={field.handleBlur}
                className="selectInput--small"
                dropdownClassName="w-full !max-h-40"
              />
            </TanstackFormInput>
          )}
        </Field>
      )}
      {hasBusinessPartner && (
        <Field name={Fields.businessPartnerId}>
          {field => (
            <TanstackFormInput
              className={classNames({ 'invisible absolute': !hasBusinessPartner })}
              label={t('lead_contributors.form.business_partner')}
              field={field}
              apiError={error?.business_partner_id}
            >
              <BusinessPartnerSelectInput
                value={hasBusinessPartner ? (field.state.value ?? null) : null}
                onChange={value => field.handleChange(value)}
                onBlur={field.handleBlur}
              />
            </TanstackFormInput>
          )}
        </Field>
      )}
      {children}
    </form>
  )
}
