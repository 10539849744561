import { toast } from 'helpers/toast'
import { BosAssignment, OpportunityFile } from 'lib/oleenApi'
import React from 'react'
import { useWizard } from 'react-use-wizard'
import { t } from 'utils/i18n'
import { BOSAssignmentFileUploadForm } from './bosAssignmentFileUploadForm'
import { useMutation } from '@tanstack/react-query'
import { useOleenApi } from 'contexts/oleenApi'
import { CLICKS, track } from 'helpers/tracking'

interface ComplementStepProps {
  bosAssignment: BosAssignment
  closeModal: () => void
}

export const ComplementStep: React.FC<ComplementStepProps> = ({ bosAssignment, closeModal }) => {
  const { oleenApi } = useOleenApi()
  const { previousStep } = useWizard()
  const onError = () => {
    toast.error(t('opportunity.bos_assignment_modal.create_error'))
  }
  const onSuccess = () => {
    toast.success(t('opportunity.bos_assignment_modal.create_success'), undefined, { autoClose: 2000 })
    closeModal()
  }

  const createBosAssignment = useMutation({
    mutationFn: (bosAssignment: BosAssignment) => {
      return oleenApi.opportunityBosAssignments().create(bosAssignment)
    },
    onSuccess,
    onError,
  })

  const action = (file: OpportunityFile | null) => {
    bosAssignment.files.complement = file
    track(CLICKS.submitBosAssignment, {
      step: 'complement',
      file: file?.filename,
      opportunityId: bosAssignment.opportunityId,
    })
    createBosAssignment.mutate(bosAssignment)
  }

  const onCancel = () => {
    track(CLICKS.previousStepBosAssignment, { step: 'complement', opportunityId: bosAssignment.opportunityId })
    previousStep()
  }
  return (
    <div className="text-xs">
      <p>{t('opportunity.bos_assignment_modal.steps.complement.introduction')}</p>
      <BOSAssignmentFileUploadForm
        action={action}
        onCancel={onCancel}
        isLoading={createBosAssignment.isPending}
        mandatory={false}
        fileType="complement"
      />
    </div>
  )
}
