import { LeadContributorManagerItem } from 'components/businessPartners/leadContributorsManager/leadContributorManagerItem'
import { LeadContributor } from 'lib/oleenApi'
import React, { useEffect, useState } from 'react'

import { LeadContributorSelectInput } from 'components/leadContributors/leadContributorSelectInput'

export type LeadContributorsManagerProps = {
  defaultLeadContributors: LeadContributor[]
  onRemove: (value: string) => void
  onAdd: (value: string) => void
}

export const LeadContributorsManager: React.FC<LeadContributorsManagerProps> = ({
  defaultLeadContributors,
  onRemove,
  onAdd,
}) => {
  const [leadContributors, setLeadContributors] = useState<LeadContributor[]>(defaultLeadContributors)

  useEffect(() => {
    setLeadContributors(defaultLeadContributors)
  }, [defaultLeadContributors])

  return (
    <>
      {leadContributors.map(leadContributor => (
        <LeadContributorManagerItem
          key={leadContributor.id}
          leadContributor={leadContributor}
          onRemove={() => {
            onRemove(leadContributor.id)
            setLeadContributors(leadContributors => leadContributors.filter(item => item.id !== leadContributor.id))
          }}
        />
      ))}

      <LeadContributorSelectInput
        value={null}
        onChange={(e, data) => {
          onAdd(e as string)
          setLeadContributors(leadContributors => [...leadContributors, data as LeadContributor])
        }}
      />
    </>
  )
}
