import { mapOpportunityToPrimaryAndSecondaryContact } from 'helpers/opportunityContact'
import React from 'react'
import { t } from 'utils/i18n'

import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { ContactCardBody } from 'ui/legacy/molecules/contactCard/contactCardBody'
import { Opportunity } from 'lib/oleenApi'

interface ContactCardProps {
  opportunity: Opportunity
  className?: string
}

export const ContactCard: React.FC<ContactCardProps> = ({ opportunity, className }) => {
  return (
    <LabelledCard
      icon={CARD_ICON.IdentificationIcon}
      title={t('opportunity.mortgagors_info_title')}
      fullHeight
      className={className}
    >
      <ContactCardBody
        primaryTitle={t('opportunity.mortgagor')}
        secondaryTitle={t('opportunity.comortgagor')}
        projectId={opportunity.projectId}
        {...mapOpportunityToPrimaryAndSecondaryContact(opportunity)}
      />
    </LabelledCard>
  )
}
