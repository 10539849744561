import { errorHandlerMode } from 'helpers/errorHandler'
import { OPPORTUNITY_FIELDS } from 'helpers/opportunity'
import React, { Ref } from 'react'
import { t } from 'utils/i18n'

import { FormInput } from 'ui/forms/formInput'
import { LabelledCardVerticalBody as VerticalBody } from 'ui/legacy/molecules/card/card.styled'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { Opportunity } from 'lib/oleenApi'

interface BackofficeCardProps {
  opportunity: Opportunity
  bankAppointmentOnRef: Ref<HTMLInputElement>
  className?: string
}

export const BackofficeCard: React.FC<BackofficeCardProps> = ({ opportunity, bankAppointmentOnRef, className }) => (
  <LabelledCard
    icon={CARD_ICON.FolderOpenIcon}
    title={t('opportunity.opportunity_back_office_title')}
    className={className}
  >
    <VerticalBody>
      <FormInput
        id="bank_appointment_on_input"
        type="date"
        name={OPPORTUNITY_FIELDS.BANK_APPOINTMENT_ON}
        label={t('opportunity.bank_appointment_on')}
        defaultValue={opportunity.bankAppointmentOn}
        validations={[{ type: errorHandlerMode.DATE }]}
        ref={bankAppointmentOnRef}
      />
      <FormInput
        type="date"
        name={OPPORTUNITY_FIELDS.BANK_OFFER_RECEIVED_ON}
        label={t('opportunity.bank_offer_received_on')}
        defaultValue={opportunity.bankOfferReceivedOn}
        validations={[{ type: errorHandlerMode.DATE }]}
      />
      <FormInput
        type="date"
        name={OPPORTUNITY_FIELDS.BANK_OFFER_ACCEPTED_ON}
        label={t('opportunity.bank_offer_accepted_on')}
        defaultValue={opportunity.bankOfferAcceptedOn}
        validations={[{ type: errorHandlerMode.DATE }]}
      />
      <FormInput
        id="bank_contact_input"
        type="textArea"
        name={OPPORTUNITY_FIELDS.BANK_CONTACT}
        label={t('opportunity.bank_contact')}
        defaultValue={opportunity.bankContact}
        placeholder={t('opportunity.bank_contact_placeholder')}
        textAreaClassName="h-80"
      />
    </VerticalBody>
  </LabelledCard>
)
