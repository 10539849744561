import { DocumentsCardCompletenessRow } from 'components/opportunity/molecules/documentsCard/documentsCardCompletenessRow'
import { DocumentsCardGenerateCertificateRow } from 'components/opportunity/molecules/documentsCard/documentsCardGenerateCertificateRow'
import { DocumentsCardGenerateConsultancyContractRow } from 'components/opportunity/molecules/documentsCard/documentsCardGenerateConsultancyContractRow'
import { DocumentsCardSendMandateRow } from 'components/opportunity/molecules/documentsCard/documentsCardSendMandateRow'
import React from 'react'
import { t } from 'utils/i18n'

import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { Opportunity } from 'lib/oleenApi'
import { DocumentsCardDERRow } from 'components/opportunity/documentsCard/documentsCardDERRow'

interface DocumentsCardProps {
  className?: string
  opportunity: Opportunity
  openMandateModal: () => void
}

export const DocumentsCard: React.FC<DocumentsCardProps> = ({ opportunity, openMandateModal, className }) => {
  const navigate = useNavigate()
  return (
    <>
      <LabelledCard icon={CARD_ICON.HomeIcon} title={t('opportunity.client_documents_title')} className={className}>
        <DocumentsCardCompletenessRow opportunity={opportunity} />
        <DocumentsCardDERRow opportunity={opportunity} />
        <DocumentsCardSendMandateRow opportunity={opportunity} openMandateModal={openMandateModal} />
        <DocumentsCardGenerateConsultancyContractRow
          opportunity={opportunity}
          openConsultancyModal={() => navigate(ROUTES.OPPORTUNITY_NEW_CONSULTANCY_CONTRACT)}
        />
        <DocumentsCardGenerateCertificateRow
          opportunity={opportunity}
          openFeasabilityModal={() => navigate(ROUTES.OPPORTUNITY_NEW_FEASABILITY_CERTIFICATE)}
        />
      </LabelledCard>
    </>
  )
}
