import { Form, FormData } from 'components/businessPartners/editBusinessPartnerForm'
import { OleenApi } from 'lib/oleenApi'
import React, { useCallback } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { Modal } from 'ui/modals/modal'
import { CLICKS } from 'helpers/tracking'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { toast } from 'helpers/toast'
import { useUpdateBusinessPartnerMutation } from 'helpers/queries'
import { useFormError } from 'helpers/errorHandler'

type EditBusinessPartnerModalProps = {
  businessPartnerId: string
  onClose: () => void
}

export const EditBusinessPartnerModal: React.FC<EditBusinessPartnerModalProps> = ({ businessPartnerId, onClose }) => {
  const queryClient = useQueryClient()
  const { error, onError } = useFormError()

  const businessPartnerQuery = useSuspenseQuery({
    queryKey: ['businessPartners', businessPartnerId],
    queryFn: params => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.businessPartners().get(params.queryKey[1])
    },
  })

  const updateBusinessPartnerMutation = useUpdateBusinessPartnerMutation({
    queryClient,
    onSuccess: () => {
      toast.success(t('business_partners.toasts.update.success'))
      onClose()
    },
    onError: error => {
      toast.error(t('business_partners.toasts.update.error'))
      onError(error)
    },
  })

  const onSubmit = useCallback(
    (formData: FormData) => {
      updateBusinessPartnerMutation.mutate({ ...formData, id: businessPartnerId })
    },
    [updateBusinessPartnerMutation, businessPartnerId]
  )

  return (
    <Modal className="modal--form" isOpen={true} onClose={onClose} title={t('business_partners.modal.edit.title')}>
      <Form businessPartner={businessPartnerQuery.data} error={error} onSubmit={onSubmit}>
        <Button
          type="submit"
          className="button--solid button--primary button--medium"
          tracking={[CLICKS.businessPartnerSave]}
        >
          <span>{t('business_partners.modal.edit.submit')}</span>
        </Button>
        <Button className="button--solid button--secondary button--medium" onClick={onClose}>
          <span>{t('common.cancel')}</span>
        </Button>
      </Form>
    </Modal>
  )
}
