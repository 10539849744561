import { track, Tracked } from 'helpers/tracking'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { DropdownOption, DropdownOptionValue, DropdownSection } from 'ui/dropdowns/dropdown/types'

import { SelectSection } from './types'

export const mapNested = <T, U>(outer: T[][], fn: (t: T) => U): U[][] => outer.map(inner => inner.map(fn))

export const filterNested = <T>(outer: T[][], fn: (t: T) => boolean): T[][] =>
  outer.map(inner => inner.filter(fn)).filter(inner => inner.length > 0)

const findOption = (options: DropdownSection[], value: DropdownOptionValue): DropdownOption | undefined => {
  const selectedOption = options.find(option =>
    option.find(suboption => 'value' in suboption && suboption.value === value)
  )
  if (selectedOption) {
    return selectedOption.find(suboption => 'value' in suboption && suboption.value === value)
  }

  return undefined
}

export type UseSelectProps = {
  options: SelectSection[]
  onChange: (value?: string | null, data?: unknown) => void
  onBlur?: () => void
  isSearchable?: boolean
  searchPlaceholder?: string
  onSearch?: (search: string) => void
  value: DropdownOptionValue
} & Partial<Tracked>

export const useSelect = ({
  options,
  onChange,
  onBlur,
  isSearchable,
  searchPlaceholder,
  onSearch,
  value,
  tracking,
}: UseSelectProps) => {
  // stores dropdown open state
  const [isOpen, setIsOpen] = useState(false)
  // stores user search text
  const [search, setSearch] = useState<string>('')
  // button dom ref
  const buttonRef = useRef<HTMLButtonElement>(null)
  // dropdown dom ref
  const dropdownRef = useRef<HTMLDivElement>(null)
  // stores the option corresponding to the selected value
  const currentOption = useMemo(() => findOption(options, value), [value, options])

  // selecting a value is equivalent to blurring out of the input
  const innerOnChange = useCallback(
    (value: DropdownOptionValue, data?: unknown) => {
      onChange(value, data)
      onBlur?.()
    },
    [onChange, onBlur]
  )

  // the select button should be blured when the dropdown closes
  useEffect(() => {
    if (!isOpen) {
      buttonRef.current?.blur()
    }
  }, [isOpen])

  // when the button is clicked, it toggles the dropdown display
  const onButtonClick = useCallback(() => {
    if (tracking) track(...tracking)
    setIsOpen(!isOpen)
  }, [isOpen, tracking])

  // clicking outised of the dropdown, except the button, should close the dropdown
  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      const clickedOutside = dropdownRef.current && !dropdownRef.current.contains(event.target as Node)
      const clickedButton = buttonRef.current && buttonRef.current.contains(event.target as Node)

      if (clickedOutside && !clickedButton) {
        setIsOpen(false)
      }
    },
    [setIsOpen, dropdownRef, buttonRef]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleOutsideClick])

  // available options should be filtered when necessary
  const filteredOtions = useMemo(() => {
    const filtered =
      search && !onSearch
        ? filterNested(
            options,
            option => 'label' in option && option.label.toLowerCase().includes(search.toLowerCase())
          )
        : options
    const mapped = mapNested(filtered, option => ({
      ...option,

      onClick: () => {
        if ('value' in option) {
          innerOnChange(option.value, option.data)
          if (tracking) track(tracking[0], { ...tracking[1], value })
          setIsOpen(false)
        }
      },
      isChecked: 'value' in option && option.value === value,
    }))
    return mapped
  }, [options, innerOnChange, value, search, tracking, onSearch])

  // a search option should be available at all time if searc his enabled
  const allOptions = useMemo(() => {
    if (!isSearchable) return filteredOtions

    return [
      [
        {
          type: 'search',
          value: search,
          placeholder: searchPlaceholder,
          className: 'sticky',
          onChange: value => {
            setSearch(value)
            onSearch?.(value)
          },
        } as DropdownOption,
      ],
      ...filteredOtions,
    ]
  }, [isSearchable, search, filteredOtions, searchPlaceholder, onSearch])

  return {
    isOpen,
    onButtonClick,
    buttonRef,
    dropdownRef,
    currentOption,
    allOptions,
  }
}
