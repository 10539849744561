import { useQueryClient } from '@tanstack/react-query'
import { Task } from 'lib/oleenApi'
import React from 'react'
import { Outlet, useParams } from 'react-router-dom'

export const TaskScope: React.FC = () => {
  const { taskId } = useParams()
  const queryClient = useQueryClient()
  const tasks: Task[] = queryClient.getQueryData(['tasks']) || []
  const task = tasks.find(t => t.id === taskId)

  if (!task) return <></>

  return <Outlet context={{ task }} />
}
