import { LeadContributorSelectInput } from 'components/leadContributors/leadContributorSelectInput'
import React, { forwardRef } from 'react'

import { InnerProps, SelectSection } from 'ui/dropdowns/selectInput'
import { FieldState } from 'ui/forms/form.hook'

export type { SelectOption, InnerProps as InnerClassNames } from 'ui/dropdowns/selectInput'

export type FormLeadContributorSelectInputCustomProps = InnerProps

export type FormLeadContributorSelectInputProps = {
  selectClassName?: string
  disabled?: boolean
  options: SelectSection[]
  defaultOptions?: SelectSection[]
} & FormLeadContributorSelectInputCustomProps &
  FieldState

export const FormLeadContributorSelectInput = forwardRef<HTMLInputElement, FormLeadContributorSelectInputProps>(
  ({ selectClassName, ...props }, ref) => {
    return (
      <LeadContributorSelectInput
        className={selectClassName}
        value={props.value as string}
        onChange={props.handleFieldChange}
        onBlur={props.handleFieldBlur}
        disabled={props.disabled}
        defaultOptions={props.defaultOptions}
      />
    )
  }
)
FormLeadContributorSelectInput.displayName = 'FormLeadContributorSelectInput'
