import { BOSAssignmentModal } from 'components/opportunity/bosAssignmentModal/bosAssignmentModal'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from 'router/routes'

export const BOSAssignmentPage: React.FC = () => {
  const navigate = useNavigate()
  const { opportunityId } = useParams()

  return opportunityId ? (
    <BOSAssignmentModal closeModal={() => navigate(ROUTES.BACK)} opportunityId={opportunityId} />
  ) : (
    <div>Opportunity ID is missing</div>
  )
}
