import { XMarkIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import React from 'react'

type PanelHeaderProps = {
  title: string
  onClose: () => void
  className?: string
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({ title, onClose, className }) => (
  <div className={classNames('flex w-full justify-between antialiased pb-4 border-b border-gray-300 px-6', className)}>
    <p className="text-3xl font-bold text-gray-900 truncate">{title}</p>

    <button onClick={onClose}>
      <XMarkIcon className="h-5 w-5" />
    </button>
  </div>
)
