import { EMPTY_SIGN, formatFullName } from 'helpers/format'
import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { t } from 'utils/i18n'

import { Table } from 'ui/tables/table'
import { InvitationButton } from 'components/leadContributors/invitationButton'
import { LeadContributor, Representative } from 'lib/oleenApi'

type LeadContributorsTableProps = {
  leadContributors: LeadContributor[]
  representative: Representative
  fetchNextPage?: () => void
}

export const LeadContributorsTable: React.FC<LeadContributorsTableProps> = ({
  leadContributors,
  representative,
  fetchNextPage,
}) => {
  return (
    <Table
      onLastRowVisible={fetchNextPage}
      cols={[
        {
          name: t('lead_contributors.table.header.lead_contributor'),
          className: 'tableCell--title max-w-[200px]',
          render: leadContributor => (
            <Link to={`${ROUTES.LEAD_CONTRIBUTORS}/${leadContributor.id}`}>
              {formatFullName(leadContributor.firstName, leadContributor.lastName, EMPTY_SIGN)}
            </Link>
          ),
          sort: 'lastName',
          sticky: true,
        },
        {
          name: t('lead_contributors.table.header.business_partner_type'),
          className: 'tableCell--text',
          render: leadContributor =>
            leadContributor.businessType
              ? t(`business_partners.business_type.${leadContributor.businessType}`)
              : EMPTY_SIGN,
        },
        {
          name: t('lead_contributors.table.header.business_partner'),
          className: 'tableCell--title',
          render: leadContributor => {
            return leadContributor.businessPartnerName ? (
              <Link to={`${ROUTES.LEAD_CONTRIBUTORS}/business-partner/${leadContributor.businessPartnerId}`}>
                {leadContributor.businessPartnerName}
              </Link>
            ) : (
              formatFullName(leadContributor.firstName, leadContributor.lastName, EMPTY_SIGN)
            )
          },
          sort: 'businessPartnerName',
        },
        {
          name: t('lead_contributors.table.header.email'),
          className: 'tableCell--text',
          render: leadContributor => leadContributor.email,
        },
        {
          name: t('lead_contributors.table.header.phone'),
          className: 'tableCell--text',
          render: leadContributor => leadContributor.phone || EMPTY_SIGN,
        },
        {
          name: t('lead_contributors.table.header.current_opportunities_count'),
          className: 'tableCell--text',
          render: leadContributor => {
            const params = new URLSearchParams({ leadContributorId: leadContributor.id })
            return (
              <Link to={`${ROUTES.OPPORTUNITIES}?${params.toString()}`}>
                {leadContributor.currentOpportunitiesCount}
              </Link>
            )
          },
          sort: 'currentOpportunitiesCount',
        },
        representative?.hasYuccanlead
          ? {
              name: t('lead_contributors.table.header.yuccanlead'),
              className: 'tableCell--text',
              render: leadContributor => <InvitationButton leadContributor={leadContributor} />,
            }
          : {
              name: '',
              className: 'hidden',
              render: () => <></>,
            }, // no choice with the current table component
      ]}
      items={leadContributors}
    />
  )
}
