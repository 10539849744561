import { ApiError, ApiErrorDetail, BaseErrorDetailType } from '../apiError'
import { Client } from '../client'
import { ObjectCamelToSnakeCase } from '../misc'
import { Paginated, Pagination, Searching } from '../types'

import { BUSINESS_TYPES as BUSINESS_PARTNER_BUSINESS_TYPES } from './businessPartnersService'
import { InvitationState } from './invitationsService'

/**
 * Augments the 'Client' class with a method for accessing lead contributors services.
 */
declare module '../client' {
  interface Client {
    /**
     * Gets an instance of the LeadContributorsService.
     * @returns LeadContributorsService instance.
     */
    leadContributors: () => LeadContributorsService
  }
}

/**
 * Extension method to get LeadContributorsService.
 */
Client.prototype.leadContributors = function () {
  return new LeadContributorsService(this)
}

/**
 * List of business types for lead contributors.
 */
export const LEAD_CONTRIBUTOR_BUSINESS_TYPES = [...BUSINESS_PARTNER_BUSINESS_TYPES, 'lead_sponsor'] as const

/**
 * Represents a business type for a lead contributor that is not a member of a business partner.
 */
export type LeadContributorBusinessType = (typeof LEAD_CONTRIBUTOR_BUSINESS_TYPES)[number]

/**
 * Represents a lead contributor.
 */
export interface LeadContributor {
  id: string
  representativeId: string | null
  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string | null
  jobTitle: string | null
  businessType: LeadContributorBusinessType
  currentOpportunitiesCount: number
  businessPartnerId: string | null
  businessPartnerName: string | null
  invitation: InvitationState | null
  invitationId: string | null
  yuccanleadId: string | null
}

export type ReadOnlyFields =
  | 'id'
  | 'businessType'
  | 'businessPartnerId'
  | 'businessPartnerName'
  | 'currentOpportunitiesCount'
  | 'invitation'
  | 'invitationId'
  | 'yuccanleadId'

/**
 * Represents the data structure for creating a new lead contributor.
 *
 * This type is used when creating a new lead contributor through the `create` method
 * of `LeadContributorsService`. It is derived from the `LeadContributor` type, but
 * omits the `id` and `businessPartner`, and includes `businessPartnerId` for linking to an existing business partner.
 * The `businessType` field is nullable.
 *
 * @type
 *
 * @template NewLeadContributor - The type derived from `LeadContributor`, omitting `id` and `businessPartner`,
 * and including `businessPartnerId` for linking to an existing business partner.
 *
 * Example Usage:
 *
 * const newLeadContributor: NewLeadContributor = {
 *   firstName: "Jane",
 *   lastName: "Doe",
 *   email: "jane.doe@example.com",
 *   phone: "123-456-7890",
 *   jobTitle: "Manager",
 *   businessType: null, // Nullable field
 *   businessPartnerId: "partner_id_123"
 * };
 *
 * leadContributorsService.create(newLeadContributor);
 */
export type NewLeadContributor = Omit<LeadContributor, ReadOnlyFields> & {
  businessType: string | null // Making businessType nullable
  businessPartnerId: string | null
}

type SerializedNewLeadContributor = ObjectCamelToSnakeCase<NewLeadContributor>

/**
 * Represents the data structure for updating an existing lead contributor.
 *
 * This type is used when updating an existing lead contributor through the `update` method
 * of `LeadContributorsService`. It is similar to `NewLeadContributor` but is intended for
 * use in update scenarios where certain fields might not be required or might be different
 * compared to the creation of a new lead contributor.
 *
 * @type
 *
 * @template UpdatedLeadContributor - Similar to `NewLeadContributor` but tailored for update operations.
 *
 * Example Usage:
 *
 * const updatedLeadContributor: UpdatedLeadContributor = {
 *   firstName: "Jane",
 *   lastName: "Doe",
 *   email: "jane.newemail@example.com",
 *   phone: "987-654-3210",
 *   jobTitle: "Senior Manager",
 *   businessType: "Retail", // Nullable field
 *   businessPartnerId: "updated_partner_id_456"
 * };
 *
 * leadContributorsService.update("lead_contributor_id", updatedLeadContributor);
 */
export type UpdatedLeadContributor = Omit<LeadContributor, ReadOnlyFields> & {
  businessType: string | null // Making businessType nullable and optional
  businessPartnerId: string | null
}

type SerializedUpdatedLeadContributor = ObjectCamelToSnakeCase<UpdatedLeadContributor>

/**
 * Represents the response structure for a lead contributor.
 */
type LeadContributorResponse = ObjectCamelToSnakeCase<LeadContributor>

/**
 * Represents the type of errors for lead contributors.
 */
export type LeadContributorErrorType = BaseErrorDetailType

/**
 * Represents the attributes for lead contributor errors
 */
export type LeadContributorErrorAttribute =
  | keyof SerializedNewLeadContributor
  | keyof SerializedUpdatedLeadContributor
  | 'business_partner'

/**
 * Represents the error details for lead contributor errors
 */
interface LeadContributorErrorDetail extends ApiErrorDetail {
  type: LeadContributorErrorType
  attribute: LeadContributorErrorAttribute
}

/**
 * Represents the error type for lead contributor errors
 * @param message - The error message.
 * @param status - The error status.
 * @param details - The error details.
 * @returns A new instance of LeadContributorApiError.
 */
export class LeadContributorApiError extends ApiError<LeadContributorErrorDetail> {
  constructor(message: string, status: number, details: LeadContributorErrorDetail[]) {
    super(message, status, details)
  }
}

type Filtering = {
  businessPartnerId?: string
}

/**
 * Interface for lead contributors service.
 */
interface ILeadContributorsService {
  list: (params: Pagination & Searching & Filtering) => Promise<Paginated<LeadContributor>>
  create: (data: NewLeadContributor) => Promise<unknown>
  get: (id: string) => Promise<LeadContributor>
  update: (id: string, data: UpdatedLeadContributor) => Promise<LeadContributor>
}

/**
 * Service for managing lead contributors.
 */
class LeadContributorsService implements ILeadContributorsService {
  constructor(private client: Client) {
    client.setErrorHandler(LeadContributorApiError)
  }

  /**
   * Lists lead contributors with pagination.
   * @param page - The page number.
   * @param pageSize - The number of items per page.
   * @returns A promise resolving to a paginated list of lead contributors.
   *
   * See the todo in `getPaginatedData` for a possible improvement.
   */
  list(params: Pagination & Searching & Filtering) {
    return this.client.getPaginatedData<LeadContributorResponse, LeadContributor>(
      `/lead_contributors?${serializeParams(params)}`,
      params.page,
      params.pageSize,
      parseLeadContributor
    )
  }

  /**
   * Lists all lead contributors.
   * @returns A promise resolving to a list of all lead contributors.
   */
  async listAll(): Promise<LeadContributor[]> {
    let nextPage: null | number = 1
    const allContributors: LeadContributor[] = []

    while (nextPage) {
      const paginatedResult: Paginated<LeadContributor> = await this.list({ page: nextPage, pageSize: 10 })
      allContributors.push(...paginatedResult.items)
      nextPage = paginatedResult.nextPage
    }

    return allContributors
  }

  /**
   * Creates a new lead contributor.
   * @param data - The data of the lead contributor to create.
   * @returns A promise resolving to the creation result.
   */
  create(data: NewLeadContributor) {
    // Logic to create a lead contributor
    return this.client.request<NewLeadContributor, LeadContributor, LeadContributorResponse>(
      {
        url: '/lead_contributors',
        method: 'POST',
        data: serializeNewLeadContributor(data),
      },
      response => parseLeadContributor(response.data)
    )
  }

  /**
   * Retrieves a specific lead contributor by ID.
   * @param id - The ID of the lead contributor.
   * @returns A promise resolving to the requested lead contributor.
   */
  get(id: string) {
    return this.client.request<LeadContributorResponse, LeadContributor>(
      {
        url: `/lead_contributors/${id}`,
        method: 'GET',
      },
      response => parseLeadContributor(response.data)
    )
  }

  /**
   * Updates a lead contributor by ID.
   * @param id - The ID of the lead contributor to update.
   * @param data - The new data for the lead contributor.
   * @returns A promise resolving to the update result.
   */
  update(id: string, data: UpdatedLeadContributor) {
    // Logic to update a lead contributor
    return this.client.request<UpdatedLeadContributor, LeadContributor, LeadContributorResponse>(
      {
        url: `/lead_contributors/${id}`,
        method: 'PUT',
        data: serializeUpdatedLeadContributor(data),
      },
      response => parseLeadContributor(response.data)
    )
  }
}

/**
 * Parses the lead contributor response to a standard format.
 * @param leadContributor - The lead contributor response object.
 * @returns The parsed lead contributor.
 */
const parseLeadContributor = (leadContributor: LeadContributorResponse): LeadContributor => {
  return {
    id: leadContributor.id,
    representativeId: leadContributor.representative_id,
    firstName: leadContributor.first_name,
    lastName: leadContributor.last_name,
    email: leadContributor.email,
    phone: leadContributor.phone,
    jobTitle: leadContributor.job_title,
    businessType: leadContributor.business_type,
    businessPartnerId: leadContributor.business_partner_id,
    businessPartnerName: leadContributor.business_partner_name,
    currentOpportunitiesCount: leadContributor.current_opportunities_count,
    invitation: leadContributor.invitation,
    invitationId: leadContributor.invitation_id,
    yuccanleadId: leadContributor.yuccanlead_id,
  }
}

const serializeNewLeadContributor = (leadContributor: NewLeadContributor): SerializedNewLeadContributor => {
  return {
    representative_id: leadContributor.representativeId,
    first_name: leadContributor.firstName,
    last_name: leadContributor.lastName,
    email: leadContributor.email,
    phone: leadContributor.phone,
    job_title: leadContributor.jobTitle,
    business_type: leadContributor.businessType,
    business_partner_id: leadContributor.businessPartnerId,
  }
}

const serializeUpdatedLeadContributor = (leadContributor: UpdatedLeadContributor): SerializedUpdatedLeadContributor => {
  return {
    representative_id: leadContributor.representativeId,
    first_name: leadContributor.firstName,
    last_name: leadContributor.lastName,
    email: leadContributor.email,
    phone: leadContributor.phone,
    job_title: leadContributor.jobTitle,
    business_type: leadContributor.businessType,
    business_partner_id: leadContributor.businessPartnerId,
  }
}

const serializeParams = (params: Pagination & Searching & Filtering) => {
  const urlParms = new URLSearchParams()
  if (params.search) {
    urlParms.append('search', params.search)

    if (params.searchBy) {
      urlParms.append('search_scope', params.searchBy)
    }
  }

  if (params.businessPartnerId) {
    urlParms.append('business_partner_id', params.businessPartnerId)
  }
  return urlParms
}
