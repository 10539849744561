import { ApiError, ApiErrorDetail, BaseErrorDetailType } from '../apiError'
import { Client } from '../client'
import { ObjectCamelToSnakeCase } from '../misc'
import { Paginated } from '../types'

/**
 * Augments the 'Client' class with a method for accessing representatives service.
 */
declare module '../client' {
  interface Client {
    /**
     * Gets an instance of the RepresentativesService.
     * @returns RepresentativesService instance.
     */
    representatives: () => RepresentativesService
  }
}

/**
 * Extension method to get RepresentativesService.
 */
Client.prototype.representatives = function () {
  return new RepresentativesService(this)
}

/**
 * Represents a representative.
 */
export interface Representative {
  id: string
  address: string
  brandName: string
  brandEmail: string | null
  companyName: string
  siret: string
  firstName: string
  lastName: string
  oriasId: string
  defaultBrokerageFees: number
  userId: string
  email: string
  phone: string
  hasYuccanlead: boolean
  externalId: string
  isDerInfosPresent: boolean
}

/**
 * Represents the data structure for creating a new representative.
 */
export type NewRepresentative = Omit<Representative, 'id' | 'userId' | 'hasYuccanlead' | 'externalId'>

type ReadOnlyFields = 'isDerInfosPresent' | 'siret'
type SerializedNewRepresentative = ObjectCamelToSnakeCase<Omit<NewRepresentative, ReadOnlyFields>>

/**
 * Represents the data structure for updating an existing representative.
 */
export type UpdatedRepresentative = Omit<
  Representative,
  'id' | 'userId' | 'email' | 'phone' | 'hasYuccanlead' | 'externalId'
>

type SerializedUpdatedRepresentative = ObjectCamelToSnakeCase<Omit<UpdatedRepresentative, ReadOnlyFields>>

/**
 * Represents the response structure for a representative.
 */
type RepresentativeResponse = ObjectCamelToSnakeCase<Representative>

/**
 * Represents the type of errors for representatives.
 */
export type RepresentativeErrorType = BaseErrorDetailType

/**
 * Represents the attributes for representative errors.
 */
export type RepresentativeAttribute = keyof SerializedNewRepresentative | keyof SerializedUpdatedRepresentative

/**
 * Represents the error details for representative errors.
 */
interface RepresentativeErrorDetail extends ApiErrorDetail {
  type: RepresentativeErrorType
  attribute: RepresentativeAttribute
}

/**
 * Represents the error class for representative errors.
 *
 * @extends ApiError
 * @template T - The type of error details.
 * @param message - The error message.
 * @param status - The HTTP status code.
 * @param details - The error details.
 */
export class RepresentativeApiError extends ApiError<RepresentativeErrorDetail> {
  constructor(message: string, status: number, details: RepresentativeErrorDetail[]) {
    super(message, status, details)
  }
}

/**
 * Interface for representatives service.
 */
interface IRepresentativesService {
  list: (page: number, pageSize: number) => Promise<Paginated<Representative>>
  create: (data: NewRepresentative) => Promise<unknown>
  get: (id: string) => Promise<Representative>
  update: (id: string, data: UpdatedRepresentative) => Promise<Representative>
  current: () => Promise<Representative>
}

/**
 * Service for managing representatives.
 */
class RepresentativesService implements IRepresentativesService {
  constructor(private client: Client) {
    client.setErrorHandler(RepresentativeApiError)
  }

  /**
   * Lists representatives with pagination.
   * @param page - The page number.
   * @param pageSize - The number of items per page.
   * @returns A promise resolving to a paginated list of representatives.
   */
  list(page = 1, pageSize = 10) {
    return this.client.getPaginatedData<RepresentativeResponse, Representative>(
      '/representatives',
      page,
      pageSize,
      parseRepresentative
    )
  }

  /**
   * Lists all representatives.
   * @returns A promise resolving to a list of all representatives.
   */
  async listAll(): Promise<Representative[]> {
    let nextPage: null | number = 1
    const allContributors: Representative[] = []

    while (nextPage) {
      const paginatedResult: Paginated<Representative> = await this.list(nextPage, 10)
      allContributors.push(...paginatedResult.items)
      nextPage = paginatedResult.nextPage
    }

    return allContributors
  }

  /**
   * Creates a new representative.
   * @param data - The data of the representative to create.
   * @returns A promise resolving to the creation result.
   */
  create(data: NewRepresentative) {
    // Logic to create a representative
    return this.client.request<NewRepresentative, Representative, RepresentativeResponse>(
      {
        url: '/representatives',
        method: 'POST',
        data: serializeNewRepresentative(data),
      },
      response => parseRepresentative(response.data)
    )
  }

  /**
   * Retrieves a specific representative by ID.
   * @param id - The ID of the representative.
   * @returns A promise resolving to the requested representative.
   */
  get(id: string) {
    return this.client.request<RepresentativeResponse, Representative>(
      {
        url: `/representatives/${id}`,
        method: 'GET',
      },
      response => parseRepresentative(response.data)
    )
  }

  /**
   * Updates a representative by ID.
   * @param id - The ID of the representative to update.
   * @param data - The new data for the representative.
   * @returns A promise resolving to the update result.
   */
  update(id: string, data: UpdatedRepresentative) {
    // Logic to update a representative
    return this.client.request<UpdatedRepresentative, Representative, RepresentativeResponse>(
      {
        url: `/representatives/${id}`,
        method: 'PUT',
        data: serializeUpdatedRepresentative(data),
      },
      response => parseRepresentative(response.data)
    )
  }

  /**
   * Retrieves the current representative
   */
  current() {
    return this.client.request<RepresentativeResponse, Representative>(
      {
        url: '/representative',
        method: 'GET',
      },
      response => parseRepresentative(response.data)
    )
  }
}

/**
 * Parses the representative response to a standard format.
 * @param representative - The representative response object.
 * @returns The parsed representative.
 */
const parseRepresentative = (representative: RepresentativeResponse): Representative => {
  return {
    id: representative.id,
    address: representative.address,
    brandName: representative.brand_name,
    brandEmail: representative.brand_email,
    companyName: representative.company_name,
    siret: representative.siret,
    firstName: representative.first_name,
    lastName: representative.last_name,
    oriasId: representative.orias_id,
    defaultBrokerageFees: representative.default_brokerage_fees,
    userId: representative.user_id,
    email: representative.email,
    phone: representative.phone,
    hasYuccanlead: representative.has_yuccanlead,
    externalId: representative.external_id,
    isDerInfosPresent: representative.is_der_infos_present,
  }
}

const serializeNewRepresentative = (representative: NewRepresentative): SerializedNewRepresentative => {
  return {
    address: representative.address,
    brand_name: representative.brandName,
    brand_email: representative.brandEmail,
    company_name: representative.companyName,
    first_name: representative.firstName,
    last_name: representative.lastName,
    orias_id: representative.oriasId,
    default_brokerage_fees: representative.defaultBrokerageFees,
    email: representative.email,
    phone: representative.phone,
  }
}

const serializeUpdatedRepresentative = (representative: UpdatedRepresentative): SerializedUpdatedRepresentative => {
  return {
    address: representative.address,
    brand_name: representative.brandName,
    brand_email: representative.brandEmail,
    company_name: representative.companyName,
    first_name: representative.firstName,
    last_name: representative.lastName,
    orias_id: representative.oriasId,
    default_brokerage_fees: representative.defaultBrokerageFees,
  }
}
