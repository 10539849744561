import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useForm } from '@tanstack/react-form'
import dayjs from 'dayjs'
import { OpportunityFile } from 'lib/oleenApi'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useWizard } from 'react-use-wizard'
import { Button } from 'ui/buttons/button'
import { FileInput } from 'ui/inputs/fileInput'
import { Checkbox } from 'ui/legacy/atoms/checkbox/checkbox.component'
import { TanstackFormInput } from 'ui/misc/tanstackFormInput'
import { Spinner } from 'ui/spinner/spinner'
import { I18NPath, t } from 'utils/i18n'

interface BOSFormProps {
  action: (file: OpportunityFile | null) => void
  mandatory: boolean
  defaultValue?: File | undefined
  fileType: string
  onCancel?: () => void
  isLoading?: boolean
}

export const BOSAssignmentFileUploadForm: React.FC<BOSFormProps> = ({
  action,
  mandatory,
  fileType,
  defaultValue,
  onCancel,
  isLoading,
}) => {
  const { isFirstStep, isLastStep } = useWizard()
  const { Field, handleSubmit, useStore } = useForm<{
    file: File | undefined
  }>({
    defaultValues: {
      file: defaultValue,
    },
    onSubmit: ({ value }) => {
      if (value.file === undefined) {
        action(null)
        return
      }

      const payload = {
        data: value.file,
        filename: `0-BOS-${value.file.name}-${dayjs().format('YYYY-MM-DD HH:mm:ss')}`,
      }

      action(payload)
    },
  })

  const fileIsSelected = useStore(state => state.values.file !== undefined)
  const [alreadyUpload, setAlreadyUpload] = useState(false)

  return (
    <form className="flex flex-col gap-4">
      {mandatory && (
        <>
          <Checkbox
            name={'alreadyUpload'}
            label={t(`opportunity.bos_assignment_modal.steps.${fileType}.checkbox` as I18NPath)}
            onChange={value => setAlreadyUpload(value)}
          />
          <p>{t('opportunity.bos_assignment_modal.file_upload.label')}</p>
        </>
      )}
      <Field name="file">
        {field => (
          <TanstackFormInput className="w-full" label={''} field={field}>
            <FileInput
              value={field.state.value}
              onChange={value => field.handleChange(value as File)}
              label={
                <Trans i18nKey="notification.opportunity_contributed.file_input_label">
                  <p>
                    {t('opportunity.bos_assignment_modal.file_upload.dragndrop')}
                    <button className="text-royalBlue-600 hover:text-blue-700 transition-colors">
                      {t('opportunity.bos_assignment_modal.file_upload.button')}
                    </button>
                    {'\n(format: pdf, png, jpeg)'}
                  </p>
                </Trans>
              }
            ></FileInput>
          </TanstackFormInput>
        )}
      </Field>

      {isLoading && <Spinner className="self-center" />}
      {!isLoading && (
        <div className="flex justify-between">
          <Button className="button--medium button--secondary button--outline" onClick={onCancel}>
            {isFirstStep
              ? t('opportunity.bos_assignment_modal.cancel')
              : t('opportunity.bos_assignment_modal.previous')}
          </Button>
          <Button
            className="button--medium button--primary button--solid"
            onClick={handleSubmit}
            disabled={mandatory && !(alreadyUpload || fileIsSelected)}
          >
            {isLastStep ? t('opportunity.bos_assignment_modal.submit') : t('opportunity.bos_assignment_modal.next')}
            {!isLastStep && <ChevronRightIcon className="w-5 h-5" />}
          </Button>
        </div>
      )}
    </form>
  )
}
