import React, { forwardRef } from 'react'

import { InnerProps, SelectInput, SelectSection } from 'ui/dropdowns/selectInput'
import { FieldState } from 'ui/forms/form.hook'

export type { SelectOption, InnerProps as InnerClassNames } from 'ui/dropdowns/selectInput'

export type FormSelectInputCustomProps = InnerProps

export type FormSelectInputProps = {
  selectClassName?: string
  disabled?: boolean
  options: SelectSection[]
} & FormSelectInputCustomProps &
  FieldState

export const FormSelectInput = forwardRef<HTMLInputElement, FormSelectInputProps>(
  ({ selectClassName, ...props }, ref) => {
    return (
      <SelectInput
        className={selectClassName}
        ref={ref}
        {...props}
        value={props.value as string}
        onChange={props.handleFieldChange}
        onBlur={props.handleFieldBlur}
      />
    )
  }
)
FormSelectInput.displayName = 'FormSelectInput'
