import { Client } from '../client'
import { parseOpportunity } from './mortgageOpportunitiesService.parsers'
import { Opportunity, OpportunityResponse } from './mortgageOpportunitiesService.types'
import axios from 'axios'

export * from './mortgageOpportunitiesService.types'

declare module '../client' {
  interface Client {
    mortgageOpportunities: () => MortgageOpportunitiesService
  }
}

Client.prototype.mortgageOpportunities = function () {
  return new MortgageOpportunitiesService(this)
}

/**
 * Represents the status of an invitation.
 */
export enum OpportunityStatus {}

export type UpdatedOpportunity = {
  bos_files_uploaded: boolean
  subtitle: string | null
  status: OpportunityStatus
  prescription_type: string
  lead_contributor_id: string
  prescription_fees: string
  bank_appointment_on: string | null
  bank_offer_received_on: string | null
  bank_offer_accepted_on: string | null
  bank_contact: string
  project_attributes: { brokerage_fees: string }
}

// type SerializedUpdatedOpportunity = ObjectCamelToSnakeCase<UpdatedOpportunity>
//
//

export type DropOpportunityParams = {
  id: string
  reason: string
  comment: string | null
  competitor_name: string | null
  loan_duration_params: string | null
  loan_rate: string | null
}

export class DropOpportunityError extends Error {
  constructor(message: string) {
    super(message)
  }
}

/**
 * Represents the service for managing mortgageOpportunities.
 */
interface IMortgageOpportunitiesService {
  listAll: () => Promise<Opportunity[]>
  get: (id: string) => Promise<Opportunity>
  update: (id: string, opportunity: UpdatedOpportunity) => Promise<void>
  duplicate: (id: string) => Promise<Opportunity>
  drop: (id: string, data: DropOpportunityParams) => Promise<void>
}

class MortgageOpportunitiesService implements IMortgageOpportunitiesService {
  constructor(private client: Client) {}

  /**
   * Lists all mortgageOpportunities.
   */
  listAll = (): Promise<Opportunity[]> => {
    return this.client
      .getPaginatedData<OpportunityResponse, Opportunity>('/mortgage/opportunities', 1, 5000, parseOpportunity)
      .then(response => response.items)
  }

  /**
   * Gets a mortgageOpportunity by id.
   */
  get = (id: string): Promise<Opportunity> => {
    return this.client.request<OpportunityResponse, Opportunity>(
      {
        method: 'GET',
        url: `/mortgage/opportunities/${id}`,
      },
      response => parseOpportunity(response.data)
    )
  }

  /**
   * Updates a mortgageOpportunity.
   */
  update = (id: string, opportunity: UpdatedOpportunity): Promise<void> => {
    return this.client.request({
      method: 'PUT',
      url: `/mortgage/opportunities/${id}`,
      data: opportunity,
    })
  }

  /**
   * Duplicates a mortgageOpportunity.
   */
  duplicate = (id: string): Promise<Opportunity> => {
    return this.client.request<OpportunityResponse, Opportunity>(
      {
        method: 'POST',
        url: `/mortgage/opportunities/${id}/duplicate`,
      },
      response => parseOpportunity(response.data)
    )
  }

  /**
   * Drops a mortgageOpportunity.
   */
  drop = (id: string, data: DropOpportunityParams): Promise<void> => {
    try {
      return this.client.request({
        method: 'POST',
        url: `/mortgage/opportunities/${id}/lose`,
        data,
      })
    } catch (error) {
      // ^ Backend api for errors is not standardized, so we need to handle them
      // manually
      if (axios.isAxiosError(error) && error.response) {
        throw new DropOpportunityError(error.response.statusText)
      }
      throw error
    }
  }
}
