import classNames from 'classnames'
import React, { forwardRef, useEffect } from 'react'

import { DropdownOptionBadge } from './dropdown/dropdownOptionBadge'
import { DropdownOptionSearch } from './dropdown/dropdownOptionSearch'
import { DropdownOptionText } from './dropdown/dropdownOptionText'
import { DropdownSection } from './dropdown/types'
import { DropdownOptionCallout } from './dropdown/dropdownOptionCallout'
import { DropdownOptionSeparator } from './dropdown/dropdownOptionSeparator'
import { useElementOnScreen } from 'hooks/elementOnScreen.hook'

export * from './dropdown/types'

export type DropdownProps = {
  className?: string
  isVisible: boolean
  options: DropdownSection[]
  onBottomReached?: () => void
}

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ isVisible, options, className, onBottomReached }, ref) => {
    const [bottomRef, isBottomVisible] = useElementOnScreen({}, onBottomReached)

    useEffect(() => {
      if (isBottomVisible) onBottomReached?.()
    }, [isBottomVisible, onBottomReached])

    if (!isVisible) return <></>

    return (
      <div ref={ref} className={classNames('dropdown', className)}>
        {options.map((suboptions, i) => (
          <div key={i} className="dropdownSection">
            {suboptions.map((option, j) => (
              <React.Fragment key={j}>
                {option.type === 'text' && <DropdownOptionText {...option} />}
                {option.type === 'search' && <DropdownOptionSearch {...option} />}
                {option.type === 'badge' && <DropdownOptionBadge {...option} />}
                {option.type === 'callout' && <DropdownOptionCallout {...option} />}
                {option.type === 'separator' && <DropdownOptionSeparator {...option} />}
              </React.Fragment>
            ))}
          </div>
        ))}
        <div ref={bottomRef} />
      </div>
    )
  }
)
Dropdown.displayName = 'Dropdown'
