import { RepresentativeProvider } from 'contexts/representative'
import { MainLayout } from 'pages/layout/mainLayout'
import React from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'

export const RepresentativeScope: React.FC = () => {
  const [searchParams] = useSearchParams()
  return (
    <>
      <RepresentativeProvider>
        <MainLayout>
          <Outlet />
        </MainLayout>
      </RepresentativeProvider>
      {searchParams.get('openStonly') && <div id={`stonly-form-${searchParams.get('openStonly')}`} />}
    </>
  )
}
