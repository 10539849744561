import React from 'react'

import { useNavigate } from 'react-router-dom'
import { NewBusinessPartnerModal } from 'components/businessPartners/newBusinessPartnerModal'

export const NewBusinessPartnerPage: React.FC = () => {
  const navigate = useNavigate()

  return <NewBusinessPartnerModal onClose={() => navigate(-1)} />
}
