import Account from 'pages/account'
import { AddonSubscriptionPage } from 'pages/account/addonSubscription.page'
import Dashboard from 'pages/dashboard'
import { LeadContributorsPage } from 'pages/leadContributors.page'
import { Logout } from 'pages/logout'
import OpportunitiesPage from 'pages/opportunities'
import { BOSAssignmentPage } from 'pages/opportunity/bosAssignment.page'
import { OpportunityCreateTaskPage } from 'pages/opportunity/createTask.page'
import { DropOpportunityPage } from 'pages/opportunity/drop.page'
import { OpportunityEmailPage } from 'pages/opportunity/email.page'
import { InsuranceOpportunityPage } from 'pages/opportunity/insurance.page'
import { NewConsultancyContractPage } from 'pages/opportunity/newConsultancyContract.page'
import { NewFeasabilityCertificatePage } from 'pages/opportunity/newFeasabilityCertificate.page'
import { NewMandatePage } from 'pages/opportunity/newMandate.page'
import { OpportunityPage } from 'pages/opportunity/opportunity.page'
import { StonlyFormPage } from 'pages/opportunity/stonlyForm.page'
import OrgMembers from 'pages/orgMembers'
import Roadmap from 'pages/roadmap'
import { RepresentativeScope } from 'pages/scopes/representativeScope'
import { TaskScope } from 'pages/scopes/taskScope'
import { TasksPage } from 'pages/tasks.page'
import { AddTaskToCalendarPage } from 'pages/tasks/addTaskToCalendar.page'
import { CreateTaskPage } from 'pages/tasks/createTask.page'
import { EditTaskPage } from 'pages/tasks/editTask.page'
import { ShowTaskPage } from 'pages/tasks/showTask.page'
import UsefulLinks from 'pages/usefulLinks'
import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'
import Sentry from 'utils/sentry'

import { ApplicationLayout } from 'layout/applicationLayout'
import { ROUTES } from './routes'

import { QueryClient } from '@tanstack/react-query'
import { PAGES } from 'helpers/tracking'
import { EditBusinessPartnerPage } from 'pages/leadContributors/editBusinessPartner.page'
import { EditLeadContributorPage } from 'pages/leadContributors/editLeadContributor.page'
import { NewBusinessPartnerPage } from 'pages/leadContributors/newBusinessPartner.page'
import { NewLeadContributorPage } from 'pages/leadContributors/newLeadContributor.page'
import { NewOpportunityPage } from 'pages/newOpportunity.page'

export const queryClient = new QueryClient()

export const MainRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)(
  createRoutesFromElements(
    <>
      <Route element={<ApplicationLayout />}>
        <Route element={<RepresentativeScope />}>
          <Route
            path={ROUTES.PRODUCTION_DASHBOARD}
            element={<Dashboard />}
            handle={{ page: PAGES.productionDashboard }}
          />
          <Route path={ROUTES.ORG_MEMBERS} element={<OrgMembers />} handle={{ page: PAGES.organization }} />
          <Route path={ROUTES.ACCOUNT} element={<Account />} handle={{ page: PAGES.account }}>
            <Route path={ROUTES.ADDON} element={<AddonSubscriptionPage />} handle={{ page: PAGES.addonSubscription }} />
            <Route
              path={ROUTES.ADDON_SUBSCRIPTION_CONFIRMATION}
              element={<AddonSubscriptionPage />}
              handle={{ page: PAGES.addonSubscription }}
            />
          </Route>
          <Route path={ROUTES.ROADMAP} element={<Roadmap />} handle={{ page: PAGES.roadmap }} />
          <Route
            path={ROUTES.LEAD_CONTRIBUTORS}
            element={<LeadContributorsPage />}
            handle={{ page: PAGES.leadContributors }}
          >
            <Route
              path={ROUTES.LEAD_CONTRIBUTOR_CREATE}
              element={<NewLeadContributorPage />}
              handle={{ page: PAGES.leadContributorCreation }}
            />
            <Route
              path={ROUTES.BUSINESS_PARTNER_CREATE}
              element={<NewBusinessPartnerPage />}
              handle={{ page: PAGES.businessPartnerCreation }}
            />
            <Route
              path={ROUTES.BUSINESS_PARTNER_EDIT}
              element={<EditBusinessPartnerPage />}
              handle={{ page: PAGES.businessPartnerEdition }}
            />
            <Route
              path={ROUTES.LEAD_CONTRIBUTOR_PATH}
              element={<EditLeadContributorPage />}
              handle={{ page: PAGES.leadContributorEdition }}
            />
          </Route>
          <Route path={ROUTES.TASKS} element={<TasksPage />} handle={{ page: PAGES.tasks }}>
            <Route path={ROUTES.TASK_CREATE} element={<CreateTaskPage />} handle={{ page: PAGES.taskCreation }} />
            <Route path={':taskId'} element={<TaskScope />}>
              <Route path={ROUTES.TASK_SHOW} element={<ShowTaskPage />} handle={{ page: PAGES.task }} />
              <Route path={ROUTES.TASK_EDIT} element={<EditTaskPage />} handle={{ page: PAGES.taskEdition }} />
              <Route
                path={ROUTES.TASK_ADD_TO_CALENDAR}
                element={<AddTaskToCalendarPage />}
                handle={{ page: PAGES.taskToCalendarExport }}
              />
            </Route>
          </Route>
          <Route path={ROUTES.LOGOUT} element={<Logout />} />
          <Route path={ROUTES.USEFUL_LINKS} element={<UsefulLinks />} handle={{ page: PAGES.usefullLinks }} />
          <Route path={ROUTES.OPPORTUNITIES} element={<OpportunitiesPage />} handle={{ page: PAGES.opportunities }}>
            <Route
              path={ROUTES.OPPORTUNITY_CREATE}
              element={<NewOpportunityPage />}
              handle={{ page: PAGES.opportunityCreation }}
            />
            <Route path={ROUTES.OPPORTUNITY_PATH} element={<OpportunityPage />} handle={{ page: PAGES.opportunity }}>
              <Route
                path={ROUTES.OPPORTUNITY_BOS_ASSIGNMENT}
                element={<BOSAssignmentPage />}
                handle={{ page: PAGES.opportunityBosAssignment }}
              />
              <Route
                path={ROUTES.OPPORTUNITY_NEW_MANDATE}
                element={<NewMandatePage />}
                handle={{ page: PAGES.opportunityMandate }}
              />
              <Route
                path={ROUTES.OPPORTUNITY_NEW_FEASABILITY_CERTIFICATE}
                element={<NewFeasabilityCertificatePage />}
                handle={{ page: PAGES.opportunityFeasabilityCertificate }}
              />
              <Route
                path={ROUTES.OPPORTUNITY_DROP}
                element={<DropOpportunityPage />}
                handle={{ page: PAGES.opportunityDrop }}
              />
              <Route
                path={ROUTES.OPPORTUNITY_INSURANCE}
                element={<InsuranceOpportunityPage />}
                handle={{ page: PAGES.opportunityInsurance }}
              />
              <Route path={ROUTES.OPPORTUNITY_STONLY_FORM} element={<StonlyFormPage />} />
              <Route
                path={ROUTES.OPPORTUNITY_NEW_CONSULTANCY_CONTRACT}
                element={<NewConsultancyContractPage />}
                handle={{ page: PAGES.opportunityConsultancyContract }}
              />
              <Route
                path={ROUTES.OPPORTUNITY_CREATE_TASK}
                element={<OpportunityCreateTaskPage />}
                handle={{ page: PAGES.opportunityTaskCreation }}
              />
              <Route path={':taskId'} element={<TaskScope />}>
                <Route path={ROUTES.TASK_SHOW} element={<ShowTaskPage />} handle={{ page: PAGES.task }} />
                <Route path={ROUTES.TASK_EDIT} element={<EditTaskPage />} handle={{ page: PAGES.taskEdition }} />
                <Route
                  path={ROUTES.TASK_ADD_TO_CALENDAR}
                  element={<AddTaskToCalendarPage />}
                  handle={{ page: PAGES.taskToCalendarExport }}
                />
              </Route>
              <Route
                path={ROUTES.OPPORTUNITY_EMAILS}
                element={<OpportunityEmailPage />}
                handle={{ page: PAGES.opportunityEmails }}
              />
              <Route
                path={ROUTES.OPPORTUNITY_NEW_LEAD_CONTRIBUTOR}
                element={<NewLeadContributorPage />}
                handle={{ pages: 'new-lea-contributor' }}
              />
            </Route>
          </Route>
          <Route index element={<Navigate to={ROUTES.TASKS} />} />
          <Route path="auth" element={<> </>} />
        </Route>
      </Route>
    </>
  )
)
