export interface ApiErrorDetail {
  type: string
  attribute: string
}

export class ApiError<T extends ApiErrorDetail> extends Error {
  status: number
  details: T[]

  constructor(message: string, status: number, details: T[]) {
    super(message)
    this.status = status
    this.details = details
    Object.setPrototypeOf(this, new.target.prototype) // Restore prototype chain
  }

  format() {
    return this.details.reduce(
      (acc, detail) => ({
        ...acc,
        [detail.attribute]: detail.type,
      }),
      {}
    )
  }
}

/**
 * Represents useful error detail types
 */
export type BaseErrorDetailType = 'invalid' | 'blank' | 'taken' | 'inclusion'

export class DefaultApiError extends ApiError<ApiErrorDetail> {}
