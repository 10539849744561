import { useBusinessPartnersInfiniteQuery } from 'helpers/queries'
import React from 'react'
import { DropdownOption, DropdownOptionValue } from 'ui/dropdowns/dropdown'
import { SelectInput } from 'ui/dropdowns/selectInput'

type BusinessPartnerSelectInputProps = {
  value: string | null
  onChange: (value: DropdownOptionValue) => void
  onBlur?: () => void
}

export const BusinessPartnerSelectInput: React.FC<BusinessPartnerSelectInputProps> = ({ value, onChange, onBlur }) => {
  const businessPartnersQuery = useBusinessPartnersInfiniteQuery()

  const options: DropdownOption[] =
    businessPartnersQuery?.data?.pages
      .flatMap(page => page.items)
      .map(businessPartner => ({
        value: businessPartner.id,
        label: businessPartner.name,
        type: 'text',
      })) ?? []

  return (
    <SelectInput
      options={[options]}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onDropdownBottomReached={() => businessPartnersQuery.fetchNextPage()}
      className="selectInput--small"
      dropdownClassName="w-full !max-h-40"
    />
  )
}
