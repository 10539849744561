import { useEffect, useState } from 'react'

export const useDebouncedState = <T>(initialValue: T, delay: number): [T, (value: T) => void] => {
  const [state, setState] = useState<T>(initialValue)
  const [debouncedState, setDebouncedState] = useState<T>(initialValue)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedState(state)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [delay, state])

  return [debouncedState, setState]
}
