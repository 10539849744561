import React from 'react'

import { useNavigate } from 'react-router-dom'
import { NewLeadContributorModal } from 'components/leadContributors/newLeadContributorModal'

export const NewLeadContributorPage: React.FC = () => {
  const navigate = useNavigate()

  return <NewLeadContributorModal onClose={() => navigate(-1)} />
}
