import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Button } from 'ui/buttons/button'
import { Modal } from 'ui/modals/modal'
import { t } from 'utils/i18n'
import { useFormContext } from 'ui/forms/form'

interface BOSAssignmentPopupModalProps {
  closeModal: () => void
  isOpen: boolean
}

export const BOSAssignmentPopupModal: React.FC<BOSAssignmentPopupModalProps> = ({ closeModal, isOpen }) => {
  const { updateFieldData } = useFormContext()
  const onClick = () => {
    closeModal()
    // This field is required to not trigger again the modal before or during the opp update
    updateFieldData({ name: 'bypass_bos_validation', value: true, valid: true })
  }

  return (
    <Modal onClose={closeModal} isOpen={isOpen} className="modal--popup">
      <div>
        <ExclamationCircleIcon className="w-[42px] h-[42px]" />
        <p>{t('opportunity.bos_assignment_popup_modal.reminder')}</p>
        <p>{t('opportunity.bos_assignment_popup_modal.additional_info')}</p>
      </div>
      <footer className="flex justify-center gap-4">
        <Button className="button--medium button--primary button--solid" onClick={onClick}>
          {t('opportunity.bos_assignment_popup_modal.confirmation')}
        </Button>
      </footer>
    </Modal>
  )
}
