import { CheckCircleIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'

import * as S from './saveBar.styled'

type ISaveBar = {
  shown: boolean
  screen?: S.SaveBarScreenType
  submit: () => Promise<boolean>
  error?: string | null
}

export const SaveBar: React.FC<ISaveBar> = ({ shown, screen, submit, error }) => {
  const [submitted, setSubmitted] = useState(false)
  const [success, setSuccess] = useState(false)
  const [displayError, setDisplayError] = useState(true)
  const [displayResult, setDisplayResult] = useState(false)

  const waitForSuccessDisplay = () => {
    const timer = setTimeout(() => {
      setDisplayResult(false)
      setSubmitted(false)
    }, 2000)

    return () => {
      clearTimeout(timer)
    }
  }

  const handleClick = async () => {
    setSubmitted(true)
    const result = await submit()
    setSuccess(result)
    setDisplayResult(true)
    waitForSuccessDisplay()
  }

  if (error) {
    setTimeout(() => {
      setDisplayError(false)
    }, 3000)
    return (
      <S.SaveBar shown={displayError} screen={screen}>
        <div className={'text-neutral text-red-600 text-xs font-medium'}>{error}</div>
        <CheckCircleIcon className={'w-[20px] text-red-600 text-xs font-medium'} />
      </S.SaveBar>
    )
  } else if (!displayError) {
    setDisplayError(true)
  }

  if (displayResult) {
    return (
      <S.SaveBar shown={shown || displayResult} screen={screen}>
        {success ? (
          <>
            <div className={'text-neutral text-green-700 text-xs font-medium'}>{t('save_bar.saved')}</div>
            <CheckCircleIcon className={'w-[20px] text-green-700 text-xs font-medium'} />
          </>
        ) : (
          <>
            <div className={'text-neutral text-red-600 text-xs font-medium'}>{t('save_bar.failed')}</div>
            <CheckCircleIcon className={'w-[20px] text-red-600 text-xs font-medium'} />
          </>
        )}
      </S.SaveBar>
    )
  }

  return (
    <S.SaveBar shown={shown} screen={screen}>
      <Button className="button--solid button--confirmation button--small" onClick={handleClick} isLoading={submitted}>
        {t('save_bar.main_cta')}
      </Button>
    </S.SaveBar>
  )
}
