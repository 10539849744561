import { ArrowTopRightOnSquareIcon, EnvelopeIcon, LinkIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { AmountSummary } from 'components/opportunity/atoms/amountSummary'
import {
  BOS_STATUSES_START_INDEX,
  getOpportunityStatusBadgeColor,
  getOpportunityTitle,
  OPPORTUNITY_FIELDS,
} from 'helpers/opportunity'
import { toast } from 'helpers/toast'
import React, { useCallback, useMemo } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { ButtonGroup } from 'ui/buttons/buttonGroup'
import { ButtonGroupItem } from 'ui/buttons/buttonGroupItem'
import { SelectOption } from 'ui/dropdowns/selectInput'
import { FormInput } from 'ui/forms/formInput'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { OleenApi, Opportunity, OPPORTUNITY_STATUS } from 'lib/oleenApi'
import { MaturityBadge } from 'components/opportunity/maturityBadge'
import { CLICKS, track } from 'helpers/tracking'
import { useSessionContext } from 'contexts/session'
import { Subtitle } from 'components/opportunity/subtitle'
import { useFeatureFlagsContext } from 'contexts/featureFlags'

interface OpportunityHeaderProps {
  opportunity: Opportunity
  onClose: () => void
}

const OpportunityHeader: React.FC<OpportunityHeaderProps> = ({ opportunity, onClose }) => {
  const { status: selectedStatus } = opportunity

  const navigate = useNavigate()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  const openUserSpace = useCallback(async () => {
    const client = new OleenApi()
    client.setAuthToken(localStorage.getItem('token') || '')
    const token = await client.opportunityToken().get(opportunity.id)
    window.open(t('url.document_workspace_impersonate', { token }), '_blank')
  }, [opportunity.id])

  const copyUserSpaceUrl = useCallback(() => {
    navigator.clipboard.writeText(t('url.document_workspace'))
    toast.success(t('opportunity.link_copied'), undefined, { autoClose: 2000 })
  }, [])

  const { impersonated } = useSessionContext()

  const options = useMemo(() => {
    const availableOptions: SelectOption[] = Object.values(OPPORTUNITY_STATUS).map((status, index) => ({
      label: t(`opportunity.statuses.${status}`),
      value: status,
      type: 'badge',
      badgeClassName: getOpportunityStatusBadgeColor(status),
      isHidden: status === OPPORTUNITY_STATUS.PERDU,
      isDisabled: index >= BOS_STATUSES_START_INDEX && !opportunity.isReadyToBOS && !impersonated,
    }))
    availableOptions.splice(BOS_STATUSES_START_INDEX, 0, {
      label: t('opportunity.statuses.bos_separator'),
      type: 'separator',
    })
    if (!opportunity.isReadyToBOS) {
      availableOptions.push({
        label: t('opportunity.statuses.missing_bos_conditions'),
        type: 'callout',
      })
    }
    return availableOptions
  }, [opportunity, impersonated])

  return (
    <div className="border-b border-gray-300 antialiased pb-4 px-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <div className="flex w-full justify-between">
            <p className="text-xl md:text-3xl md:font-bold text-gray-900 truncate">
              {getOpportunityTitle(opportunity)}
            </p>

            <button onClick={onClose}>
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
          <Subtitle subtitle={opportunity.subtitle} />
        </div>

        <FormInput
          dropdownClassName="left-0 max-h-max w-56"
          name={OPPORTUNITY_FIELDS.STATUS}
          defaultValue={selectedStatus}
          type="selectBadge"
          options={[options]}
        />

        <div className="flex gap-x-2 flex-wrap">
          <MaturityBadge maturity={opportunity.maturity} />
          {opportunity.loanAmount && (
            <AmountSummary label={t('opportunity.loan_amount')} amount={opportunity.loanAmount} />
          )}
          <AmountSummary label={t('opportunity.brokerage_fees')} amount={opportunity.brokerageFees || 0} />
          <AmountSummary label={t('opportunity.bank_commission')} amount={opportunity.bankCommission || 0} />
        </div>

        <div className="flex gap-2">
          <Button
            className="button--outline button--secondary button--small"
            onClick={() => {
              track(CLICKS.opportunityEmailModels)
              navigate(`./${ROUTES.OPPORTUNITY_EMAILS}`)
            }}
          >
            <EnvelopeIcon className="w-5 h-5" />
            {t('opportunity.emails')}
          </Button>
          <ButtonGroup>
            <ButtonGroupItem
              position="left"
              state="default"
              title={!opportunity.projectId ? t('opportunity.zou_sync_processing') : t('opportunity.open_user_space')}
              onClick={openUserSpace}
              disabled={!opportunity.projectId}
              tracking={[CLICKS.opportunityOpenUserSpace]}
            >
              {t('opportunity.user_space')}
            </ButtonGroupItem>
            <ButtonGroupItem
              tracking={[CLICKS.opportunityUserSpaceUrlCopy]}
              title={t('opportunity.copy_link')}
              position="right"
              state="default"
              Icon={LinkIcon}
              onClick={copyUserSpaceUrl}
              disabled={!opportunity.projectId}
              className="border-l-0"
            />
          </ButtonGroup>
          <Button
            className="button--outline button--secondary button--small uppercase"
            tracking={[CLICKS.opportunityOpenZou]}
            onClick={() => window.open(t('url.zou_project', { projectId: opportunity.projectId }), '_blank')}
            disabled={!opportunity.projectId}
            title={!opportunity.projectId ? t('opportunity.zou_sync_processing') : undefined}
          >
            {t('opportunity.zou')}
            <ArrowTopRightOnSquareIcon />
          </Button>
          {isFeatureFlagEnabled('bos_assignment') && (
            <Button
              className="button--outline button--secondary button--small"
              tracking={[CLICKS.bosAssignmentOpen]}
              onClick={() => navigate(`${ROUTES.OPPORTUNITY_BOS_ASSIGNMENT}`)}
            >
              {t('opportunity.bos_assignment')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default OpportunityHeader
