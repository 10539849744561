import { CheckIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

import { Badge, BadgeProps } from 'ui/badges/badge'
import { DropdownOptionValue } from 'ui/dropdowns/dropdown/types'

export type DropdownOptionBadgeProps = {
  className?: string
  badgeClassName: string
  isChecked?: boolean
  label: string
  title?: string
  value?: DropdownOptionValue
  isHidden?: boolean
  isDisabled?: boolean
} & Omit<BadgeProps, 'isCloseable' | 'className'>

export const DropdownOptionBadge: React.FC<PropsWithChildren<DropdownOptionBadgeProps>> = ({
  className,
  badgeClassName,
  isChecked,
  label,
  title,
  onClick,
  value,
  isHidden,
  isDisabled = false,
  ...badgeProps
}) => {
  if (isHidden) return <></>

  return (
    <button
      value={value ?? undefined}
      className={classNames('dropdownItem--badge', className, { dropdownItem: !isDisabled })}
      onClick={onClick}
      disabled={isDisabled}
      title={title}
    >
      <Badge className={classNames(badgeClassName, { 'badge--disabled': isDisabled })} {...badgeProps}>
        {label}
      </Badge>
      {isChecked && <CheckIcon />}
    </button>
  )
}
