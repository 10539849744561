import React, { useState } from 'react'
import { BosAssignment, Opportunity } from 'lib/oleenApi'
import { I18NPath, t } from 'utils/i18n'

import { Modal } from 'ui/modals/modal'
import { useWizard, Wizard } from 'react-use-wizard'
import classNames from 'classnames'
import { BankAgreementStep } from './bankAgreementStep'
import { SaleAgreementStep } from './saleAgreementStep'
import { InstructionsStep } from './InstructionsStep'
import { ComplementStep } from './complementStep'
import { CLICKS, track } from 'helpers/tracking'

interface BOSModalProps {
  closeModal: () => void
  opportunityId: Opportunity['id']
}

const STEPS = ['bank_agreement', 'sale_agreement', 'instructions', 'complement']

const Header: React.FC = () => {
  const { activeStep, goToStep } = useWizard()
  const onClick = (index: number) => {
    track(CLICKS.selectBosAssignmentStep, { step: STEPS[index] })
    if (index < activeStep) {
      goToStep(index)
    }
  }

  return (
    <div>
      <div className="flex flex-row justify-evenly bg-gray-100 text-sm leading-6 rounded-full">
        {STEPS.map((step, index) => (
          <div
            key={step}
            className={classNames(
              'py-1',
              activeStep === index
                ? 'text-royalBlue-600 font-semibold'
                : activeStep < index
                  ? 'text-gray-400'
                  : 'text-gray-900 hover:cursor-pointer'
            )}
            onClick={() => onClick(index)}
          >
            {t(`opportunity.bos_assignment_modal.steps.${step}.title` as I18NPath)}
          </div>
        ))}
      </div>
    </div>
  )
}

export const BOSAssignmentModal: React.FC<BOSModalProps> = ({ closeModal, opportunityId }) => {
  const [bosAssignment, setBosAssignment] = useState<BosAssignment>({
    allowToContactClient: null,
    loanOfferChecked: null,
    bankContactGiven: null,
    notaryContactGiven: null,
    additionalInformation: null,
    opportunityId,
    files: {
      bankAgreement: null,
      saleAgreement: null,
      complement: null,
    },
  })

  return (
    <Modal
      className="modal--form md:w-[600px] fixed [&>section>div:nth-child(2)]:pb-4"
      isOpen={true}
      onClose={closeModal}
      title={t('opportunity.bos_assignment_modal.title')}
    >
      <Wizard header={<Header />}>
        <BankAgreementStep closeModal={closeModal} setBosAssignment={setBosAssignment} bosAssignment={bosAssignment} />
        <SaleAgreementStep setBosAssignment={setBosAssignment} bosAssignment={bosAssignment} />
        <InstructionsStep setBosAssignment={setBosAssignment} bosAssignment={bosAssignment} />
        <ComplementStep closeModal={closeModal} bosAssignment={bosAssignment} />
      </Wizard>
    </Modal>
  )
}
