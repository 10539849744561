import {
  keepPreviousData,
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { FormData as LeadContributorFormData } from 'components/leadContributors/leadContributorForm'
import { FormData as BusinessPartnerFormData } from 'components/businessPartners/editBusinessPartnerForm'
import {
  BusinessPartner,
  LeadContributor,
  NewBusinessPartner,
  NewOpportunity,
  OleenApi,
  Opportunity,
  Task,
  TaskFilters,
  UpdatedLeadContributor,
} from 'lib/oleenApi'

export const mortgageOpportunityQuery = (id: string) => ({
  // TODO: centralize query key management to facilitate invalidation
  queryKey: ['mortgageOpportunity', id],
  queryFn: () => {
    const client = new OleenApi()
    client.setAuthToken(localStorage.getItem('token') ?? '')
    return client.mortgageOpportunities().get(id)
  },
})

export const mortgageOpportunitiesQuery = () => ({
  // TODO: centralize query key management to facilitate invalidation
  queryKey: ['mortgageOpportunities'],
  queryFn: () => {
    const client = new OleenApi()
    client.setAuthToken(localStorage.getItem('token') ?? '')
    return client.mortgageOpportunities().listAll()
  },
})

export const getMortgageOpportunityQueryData = (queryClient: QueryClient, opportunityId: string) => {
  const opportunities: Opportunity[] = queryClient.getQueryData(['mortgageOpportunities']) || []
  return opportunities.find((opp: Opportunity) => opp.id === opportunityId)
}

export const updateMortgageOpportunityQueryData = (queryClient: QueryClient, opportunity: Opportunity) => {
  queryClient.setQueryData(['mortgageOpportunities'], (opportunities: Opportunity[]) => {
    return opportunities.map((opp: Opportunity) => (opp.id === opportunity?.id ? opportunity : opp))
  })
}

type NewOpportunityMutationProps = {
  queryClient: QueryClient
  onSuccess: (id: string) => void
}

export const getNewMortgageOpportunityMutation = ({ queryClient, onSuccess }: NewOpportunityMutationProps) => ({
  mutationFn: (newOpportunity: NewOpportunity) => {
    const client = new OleenApi()
    client.setAuthToken(localStorage.getItem('token') ?? '')
    return client.newMortgageOpportunity().create(newOpportunity)
  },
  onSuccess: (data: { id: string }) => {
    queryClient.invalidateQueries({ queryKey: ['mortgageOpportunities'] })
    onSuccess(data.id)
  },
})

export const tasksQuery = (filters: TaskFilters) => ({
  queryKey: ['tasks'],
  queryFn: () => {
    const client = new OleenApi()
    client.setAuthToken(localStorage.getItem('token') ?? '')
    return client.tasks().listAll(filters)
  },
})

export const tasksMetaQuery = () => ({
  queryKey: ['tasks', 'meta'],
  queryFn: () => {
    const client = new OleenApi()
    client.setAuthToken(localStorage.getItem('token') ?? '')
    return client.tasks().list(1, 0)
  },
})

export const updateTaskCompletionQuery = ({ task, value }: { task: Task; value: boolean }) => {
  const client = new OleenApi()
  client.setAuthToken(localStorage.getItem('token') ?? '')
  const completedAt = value ? new Date().toString() : null
  task.completedAt = completedAt
  return client.tasks().complete(task.id, completedAt)
}

export const updateTaskQueryData = (queryClient: QueryClient, task: Task) => {
  const tasks: Task[] = queryClient.getQueryData(['tasks']) || []
  queryClient.setQueryData(
    ['tasks'],
    tasks.map((t: Task) => (t.id === task.id ? task : t))
  )
}

export const sendDERQuery = (opportunityId: string) => {
  const client = new OleenApi()
  client.setAuthToken(localStorage.getItem('token') ?? '')
  return client.opportunityDER().create(opportunityId)
}

export const useBusinessPartnersInfiniteQuery = (search?: string) =>
  useInfiniteQuery({
    queryKey: ['businessPartners', { name: search }],
    queryFn: ({ pageParam }) => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.businessPartners().list(pageParam, 50, { search, searchBy: 'name' })
    },
    initialPageParam: 0,
    getNextPageParam: lastPage => lastPage.nextPage,
    placeholderData: keepPreviousData,
  })

export const useLeadContributorsTotalQuery = () =>
  useQuery({
    queryKey: ['leadContributorsTotal'],
    queryFn: () => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.leadContributors().list({ page: 1, pageSize: 25 })
    },
    select: data => data.total,
  })

export const useLeadContributorsInfiniteQuery = (search: string) =>
  useInfiniteQuery({
    queryKey: ['leadContributors', { fullName: search }],
    queryFn: ({ pageParam }) => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.leadContributors().list({ page: pageParam, pageSize: 25, searchBy: 'full_name', search })
    },
    initialPageParam: 1,
    getNextPageParam: lastPage => lastPage.nextPage,
    placeholderData: keepPreviousData,
  })

export const useLeadContributorSuspenseQuery = (id: string) =>
  useSuspenseQuery({
    queryKey: ['leadContributors', id],
    queryFn: params => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.leadContributors().get(params.queryKey[1])
    },
  })

type GetMutationParams<T> = {
  queryClient: QueryClient
  onError: (error: Error) => void
  onSuccess: (data: T) => void
}

export const useCreateLeadContributorMutation = ({
  queryClient,
  onSuccess,
  onError,
}: GetMutationParams<LeadContributor>) =>
  useMutation({
    mutationFn: ({
      leadContributor,
      representativeId,
    }: {
      leadContributor: LeadContributorFormData
      representativeId: string
    }) => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.leadContributors().create({ ...leadContributor, representativeId })
    },
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['leadContributors'] })
      onSuccess(data)
    },
    onError,
  })

export const useUpdateLeadContributorMutation = ({
  queryClient,
  onSuccess,
  onError,
}: GetMutationParams<LeadContributor>) =>
  useMutation({
    mutationFn: (original_data: UpdatedLeadContributor & { id: string }) => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      const { id, ...leadContributorData } = original_data
      return client.leadContributors().update(id, leadContributorData)
    },
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['leadContributors'] })
      onSuccess(data)
    },
    onError,
  })

export const useCreateBusinessPartnerMutation = ({
  queryClient,
  onSuccess,
  onError,
}: GetMutationParams<BusinessPartner>) =>
  useMutation({
    mutationFn: (businessPartner: NewBusinessPartner) => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.businessPartners().create(businessPartner)
    },
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['businessPartners'] })
      onSuccess(data)
    },
    onError,
  })

// FIXME: this query does not really scale as we blindly fetch the first 100
// lead. There could be more contributors in a business partner. In reality, it
// will not be a problem for a long time so fix it if you have time to waist.
export const useBusinessPartnerLeadContributorsQuery = (businessPartnerId: string) =>
  useQuery<LeadContributor[]>({
    queryKey: ['leadContributors', { businessPartnerId }],
    queryFn: async () => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      // TODO: It would be fancy to be able to  `client.businessPartners(businessPartnerId).leadContributors().list())`
      const data = await client.leadContributors().list({ page: 1, pageSize: 100, businessPartnerId })
      return data.items
    },
  })

export const useUpdateBusinessPartnerMutation = ({
  queryClient,
  onSuccess,
  onError,
}: GetMutationParams<BusinessPartner>) =>
  useMutation({
    mutationFn: (original_data: BusinessPartnerFormData & { id: string }) => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      const { id, ...businessPartnerData } = original_data
      return client.businessPartners().update(id, businessPartnerData)
    },
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['businessPartners'] })
      queryClient.invalidateQueries({ queryKey: ['leadContributors'] })
      onSuccess(data)
    },
    onError,
  })
