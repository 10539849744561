import React, { Suspense } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { EditLeadContributorModal } from 'components/leadContributors/editLeadContributorModal'

export const EditLeadContributorPage: React.FC = () => {
  const navigate = useNavigate()
  const leadContributorId = useParams().leadContributorId

  // TODO: how to avoid this check
  if (!leadContributorId) return <></>

  return (
    <Suspense fallback=<></>>
      <EditLeadContributorModal onClose={() => navigate(-1)} leadContributorId={leadContributorId} />
    </Suspense>
  )
}
