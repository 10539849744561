import { InformationCircleIcon, UserGroupIcon } from '@heroicons/react/20/solid'
import { BusinessPartner, BusinessPartnerErrorAttribute, BusinessPartnerErrorType } from 'lib/oleenApi'
import { BUSINESS_TYPES } from 'lib/oleenApi/services/businessPartnersService'
import React, { PropsWithChildren, Suspense } from 'react'
import { t } from 'utils/i18n'

import { useForm } from '@tanstack/react-form'
import { TanstackFormInput } from 'ui/misc/tanstackFormInput'
import { TextInput } from 'ui/inputs/textInput'
import { selectInputProps, textInputProps } from 'helpers/tanstackForm'
import { SelectInput } from 'ui/dropdowns/selectInput'
import { LeadContributorsManager } from './leadContributorsManager/leadContributorsManager'
import { useBusinessPartnerLeadContributorsQuery } from 'helpers/queries'

enum Fields {
  name = 'name',
  businessType = 'businessType',
  leadContributorIds = 'leadContributorIds',
}

export type FormData = {
  name: string
  businessType: string
  leadContributorIds: string[]
}

export type EditBusinessPartnerFormError = Partial<Record<BusinessPartnerErrorAttribute, BusinessPartnerErrorType>>

const options = BUSINESS_TYPES.map(value => ({
  value,
  label: t(`business_partners.business_type.${value}`),
  type: 'text' as const,
}))

type FormProps = PropsWithChildren<{
  error?: EditBusinessPartnerFormError
  businessPartner: BusinessPartner
  onSubmit?: (formData: FormData) => void
}>

type CardProps = {
  title: string
  Icon?: HeroIcon
}

const Card: React.FC<PropsWithChildren<CardProps>> = ({ title, Icon, children }) => {
  return (
    <div className="flex flex-col rounded-lg">
      <div className="border rounded-t-lg flex items-center justify-start p-2 bg-gray-100 gap-2 text-sm font-bold leading-6 antialiased">
        {Icon && <Icon className="w-5 h-5 text-gray-500" />} {title}
      </div>
      <div className="border border-t-0 rounded-b-lg flex flex-col gap-4 p-4">{children}</div>
    </div>
  )
}

export const Form: React.FC<FormProps> = ({ error, businessPartner, onSubmit, children }) => {
  const { Field, handleSubmit } = useForm({
    defaultValues: {
      [Fields.name]: businessPartner.name,
      [Fields.businessType]: businessPartner.businessType,
      [Fields.leadContributorIds]: businessPartner.leadContributorIds,
    },
    onSubmit: ({ value }) => {
      onSubmit?.(value as FormData)
    },
  })

  const leadContributorsQuery = useBusinessPartnerLeadContributorsQuery(businessPartner.id)

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        handleSubmit()
      }}
    >
      <Card title="Nom" Icon={InformationCircleIcon}>
        <Field name={Fields.name}>
          {field => (
            <TanstackFormInput
              className="w-full"
              label={t('business_partners.form.name.label')}
              field={field}
              apiError={error?.name}
            >
              <TextInput placeholder={t('business_partners.form.name.placeholder')} {...textInputProps(field, error)} />
            </TanstackFormInput>
          )}
        </Field>
        <Field name={Fields.businessType}>
          {field => (
            <TanstackFormInput
              className="w-full"
              label={t('business_partners.form.type.label')}
              field={field}
              apiError={error?.name}
            >
              <SelectInput dropdownClassName="w-full" {...selectInputProps(field, [options])} />
            </TanstackFormInput>
          )}
        </Field>
      </Card>
      <Card title="Contact" Icon={UserGroupIcon}>
        <Suspense fallback=<></>>
          <Field name={Fields.leadContributorIds} mode="array">
            {field => (
              <LeadContributorsManager
                defaultLeadContributors={leadContributorsQuery.data ?? []}
                onRemove={value => {
                  field.removeValue(field.state.value.indexOf(value))
                }}
                onAdd={field.pushValue}
              />
            )}
          </Field>
        </Suspense>
      </Card>
      {children}
    </form>
  )
}
