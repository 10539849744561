import { toast } from 'helpers/toast'
import { BosAssignment, OpportunityFile } from 'lib/oleenApi'
import React from 'react'
import { useWizard } from 'react-use-wizard'
import { t } from 'utils/i18n'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useOleenApi } from 'contexts/oleenApi'
import { CLICKS, track } from 'helpers/tracking'
import { Button } from 'ui/buttons/button'
import { mortgageOpportunityQuery } from 'helpers/queries'
import { Spinner } from 'ui/spinner/spinner'

interface ComplementStepProps {
  bosAssignment: BosAssignment
  closeModal: () => void
}

export const ComplementStep: React.FC<ComplementStepProps> = ({ bosAssignment, closeModal }) => {
  const { oleenApi } = useOleenApi()
  const { previousStep } = useWizard()
  const queryClient = useQueryClient()
  const opportunityQuery = useQuery(mortgageOpportunityQuery(bosAssignment.opportunityId))

  const onError = () => {
    toast.error(t('opportunity.bos_assignment_modal.create_error'))
  }

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['mortgageOpportunity', bosAssignment.opportunityId] })
    queryClient.invalidateQueries({ queryKey: ['mortgageOpportunities'] })
    toast.success(t('opportunity.bos_assignment_modal.create_success'), undefined, { autoClose: 2000 })
    closeModal()
  }

  const createBosAssignment = useMutation({
    mutationFn: (bosAssignment: BosAssignment) => {
      return oleenApi.opportunityBosAssignments().create(bosAssignment)
    },
    onSuccess,
    onError,
  })

  const action = (file: OpportunityFile | null) => {
    bosAssignment.files.complement = file
    track(CLICKS.submitBosAssignment, {
      step: 'complement',
      file: file?.filename,
      opportunityId: bosAssignment.opportunityId,
    })
    createBosAssignment.mutate(bosAssignment)
  }

  const onCancel = () => {
    track(CLICKS.previousStepBosAssignment, { step: 'complement', opportunityId: bosAssignment.opportunityId })
    previousStep()
  }
  return (
    <div className="text-xs">
      <p>{t('opportunity.bos_assignment_modal.steps.complement.introduction')}</p>
      <div>
        <p>{t('opportunity.bos_assignment_modal.steps.complement.reminder')}</p>
        <ul className="list-disc list-inside">
          <li>{t('opportunity.bos_assignment_modal.steps.complement.documents.acquisition')}</li>
          <li>{t('opportunity.bos_assignment_modal.steps.complement.documents.works')}</li>
          <li>{t('opportunity.bos_assignment_modal.steps.complement.documents.vefa')}</li>
          <li>{t('opportunity.bos_assignment_modal.steps.complement.documents.other')}</li>
        </ul>
      </div>
      <p>
        {t('opportunity.bos_assignment_modal.steps.complement.drive_link')}
        <button className="text-royalBlue-600 hover:text-blue-700 transition-colors">
          {opportunityQuery.data?.storageUrl}
        </button>
      </p>
      <div className="flex justify-between mt-24">
        <Button className="button--medium button--secondary button--outline" onClick={onCancel}>
          {t('opportunity.bos_assignment_modal.previous')}
        </Button>
        {!createBosAssignment.isPending && (
          <Button className="button--medium button--primary button--solid" onClick={() => action(null)}>
            {t('opportunity.bos_assignment_modal.submit')}
          </Button>
        )}
        {createBosAssignment.isPending && <Spinner className="self-center" />}
      </div>
    </div>
  )
}
