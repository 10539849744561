import { ObjectCamelToSnakeCase } from 'lib/oleenApi/misc'
import { Client } from '../../client'
import { OpportunityFile } from './opportunityFilesService'

/**
 * Augments the 'Client' class with a method for accessing BosAssignments services.
 */
declare module '../../client' {
  interface Client {
    /**
     * Gets an instance of the BosAssignmentsService.
     * @returns BosAssignmentsService instance.
     */
    opportunityBosAssignments: () => BosAssignmentsService
  }
}

/**
 * Extension method to get BosAssignmentsService.
 */
Client.prototype.opportunityBosAssignments = function () {
  return new BosAssignmentsService(this)
}

export interface BosAssignment {
  allowToContactClient: boolean | null
  loanOfferChecked: boolean | null
  bankContactGiven: boolean | null
  notaryContactGiven: boolean | null
  additionalInformation: string | null
  opportunityId: string
  files: {
    bankAgreement: OpportunityFile | null
    saleAgreement: OpportunityFile | null
    complement: OpportunityFile | null
  }
}

// type SerializedBosAssignment = ObjectCamelToSnakeCase<Omit<BosAssignment, 'files'>> & {
//   files: OpportunityFile[]
// }
type SerializedBosAssignment = ObjectCamelToSnakeCase<Omit<BosAssignment, 'files'>> & {
  files: {
    bank_agreement: OpportunityFile | null
    sale_agreement: OpportunityFile | null
    complement: OpportunityFile | null
  }
}

const serializeCreatedBosAssignment = (bosAssignment: BosAssignment): SerializedBosAssignment => {
  return {
    allow_to_contact_client: bosAssignment.allowToContactClient,
    loan_offer_checked: bosAssignment.loanOfferChecked,
    bank_contact_given: bosAssignment.bankContactGiven,
    notary_contact_given: bosAssignment.notaryContactGiven,
    additional_information: bosAssignment.additionalInformation,
    opportunity_id: bosAssignment.opportunityId,
    files: {
      bank_agreement: bosAssignment.files.bankAgreement,
      sale_agreement: bosAssignment.files.saleAgreement,
      complement: bosAssignment.files.complement,
    },
  }
}

/**
 * Service for managing BosAssignments.
 */
interface IBosAssignmentsService {
  create: (data: BosAssignment, opportunityId: string) => Promise<void>
}

class BosAssignmentsService implements IBosAssignmentsService {
  constructor(private client: Client) {
    client.axiosInstance.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  }

  /**
   * Creates a new lead contributor.
   * @param data - The data of the task to create.
   * @returns A promise resolving to the creation result.
   */
  create(data: BosAssignment) {
    return this.client.request<BosAssignment, void, unknown>({
      url: `/bos_assignments`,
      method: 'POST',
      data: { bos_assignment: serializeCreatedBosAssignment(data) },
    })
  }
}
