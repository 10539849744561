import { NewTaskRow } from 'components/opportunity/molecules/tasksCard/newTaskRow'
import { TaskItemRow } from 'components/opportunity/molecules/tasksCard/taskItemRow'
import React from 'react'
import { t } from 'utils/i18n'

import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { Opportunity } from 'lib/oleenApi'
import { useQuery } from '@tanstack/react-query'
import { tasksQuery } from 'helpers/queries'

interface TasksCardProps {
  opportunity: Opportunity
  className?: string
}

export const TasksCard: React.FC<TasksCardProps> = ({ opportunity, className }) => {
  const filters = { completed: 'false', taskableId: opportunity.id }
  const { data: tasks } = useQuery(tasksQuery(filters))

  return (
    <>
      <LabelledCard icon={CARD_ICON.CalendarDaysIcon} title={t('opportunity.tasks_card_title')} className={className}>
        {tasks && tasks.map(task => <TaskItemRow key={task.id} task={task} />)}
        <NewTaskRow opportunity={opportunity} />
      </LabelledCard>
    </>
  )
}
