import { Buffer } from 'buffer'
import { trackEvent, TRACKING_EVENT } from 'helpers/segment'
import { trackUser } from 'helpers/tracking'
import { useImpersonate } from 'hooks/session.hook'
import { jwtDecode } from 'jwt-decode'
import React, { createContext, Suspense, useCallback, useContext, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Auth0 } from 'utils/auth0'
import Sentry from 'utils/sentry'

import { Loader } from 'ui/legacy/atoms/loader/loader'
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js'
import { configureStonly } from './representative'

export interface ISessionObject {
  token: string | null
}

interface ISessionContext {
  session?: ISessionObject
  email?: string | undefined
  logout?: () => void
}

const rawSession = { token: null }
const AUTH0_RESPONSE_TYPE = 'token'
const AUTH_REDIRECT_PATH = '/auth'
const AUDIENCE = process.env.AUTH0_AUDIENCE

export const SessionContext = createContext<ISessionContext>({ session: rawSession })
export const useSessionContext: () => ISessionContext = () => useContext(SessionContext)

const isTokenExpired = (token: string) => {
  const decoded = jwtDecode(token)
  if (decoded.exp === undefined) return false
  const expiration = decoded.exp * 1000
  return Date.now() >= expiration
}

export const SessionProvider: React.FC = ({ children }) => {
  const [session, setSession] = useState<ISessionObject>(rawSession)
  const isSessionActive = session.token !== null && !isTokenExpired(session.token) // FIXME: should check token expiration
  const [searchParams] = useSearchParams()

  const impersonate = useImpersonate(isSessionActive)
  const email = useMemo(() => {
    if (session.token) {
      const decoded: Record<string, string> = jwtDecode(session.token)
      return decoded['https://oleen.team/claims/email']
    }
  }, [session])

  const getAppState = useCallback(() => {
    const paramsAppState = searchParams.get('appState')

    return paramsAppState === null
      ? window.location.origin + window.location.pathname + window.location.search
      : Buffer.from(paramsAppState, 'base64').toString()
  }, [searchParams])

  const loginSilentlyOrRedirectToAuth = useCallback(async () => {
    if (window.location.pathname === AUTH_REDIRECT_PATH) {
      // if the redirect URI is hit, we validate that login was successful with Auth0
      const payload = await Auth0.handleRedirectCallback()
      if (payload.appState) window.location.replace(payload.appState)
    } else {
      const appState = getAppState()
      const redirectUri = `${window.location.origin}${AUTH_REDIRECT_PATH}`
      const loginHint = searchParams.get('user')

      const auth0Params = {
        appState,
        audience: AUDIENCE,
        impersonate: impersonate ?? undefined,
        login_hint: loginHint ?? undefined,
        redirect_uri: redirectUri,
        response_type: AUTH0_RESPONSE_TYPE,
      }

      const getTokenSilentlyOptions: GetTokenSilentlyOptions = {
        authorizationParams: auth0Params,
      }

      const token = await Auth0.getTokenSilently(getTokenSilentlyOptions).catch(() => {
        Auth0.loginWithRedirect({
          appState,
          authorizationParams: auth0Params,
        })
      })

      if (token) {
        const decoded: Record<string, string> = jwtDecode(token)
        // WARN: this ties us to Auth0 with google workspace connection
        // it's ok for now, but we should consider removing this coupling
        const email = decoded.sub.replace(/^google-apps\|/, '')

        if (email.endsWith('@oleen.fr')) {
          return Auth0.logout({ logoutParams: { returnTo: window.location.origin } })
        }

        setSession({ token })
        localStorage.setItem('token', token)
        configureStonly(email)
        trackUser(email)
        Sentry.setUser({ email })

        if (Object.keys(decoded).includes('https://oleen.team/claims/impersonate'))
          Sentry.setContext('devtools', { impersonate: true })
      }
    }
  }, [searchParams, getAppState, impersonate])

  const logout = useCallback(() => {
    trackEvent(TRACKING_EVENT.ACCOUNT_PARAMETERS.DISCONNECTED)
    Auth0.logout({ logoutParams: { returnTo: window.location.origin } })
  }, [])

  if (!isSessionActive) {
    loginSilentlyOrRedirectToAuth()
  }

  return (
    <SessionContext.Provider value={{ session, email, logout }}>
      <Suspense fallback={<Loader />}>{session?.token ? children : <Loader />}</Suspense>
    </SessionContext.Provider>
  )
}
