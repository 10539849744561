import { Form, FormData } from 'components/leadContributors/leadContributorForm'
import { toast } from 'helpers/toast'
import React, { useCallback } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { Modal } from 'ui/modals/modal'
import { CLICKS } from 'helpers/tracking'
import { useQueryClient } from '@tanstack/react-query'
import { useRepresentativeContext } from 'contexts/representative'
import { useCreateLeadContributorMutation } from 'helpers/queries'
import { useFormError } from 'helpers/errorHandler'

interface NewLeadContributorModalProps {
  onClose: () => void
}

export const NewLeadContributorModal: React.FC<NewLeadContributorModalProps> = ({ onClose }) => {
  const queryClient = useQueryClient()
  const { representative } = useRepresentativeContext()
  const { error, onError } = useFormError()

  const mutation = useCreateLeadContributorMutation({
    queryClient,
    onSuccess: () => {
      toast.success(t('lead_contributors.toast.creation.success'))
      onClose()
    },
    onError: error => {
      toast.error(t('lead_contributors.toast.creation.error'))
      onError(error)
    },
  })

  const onSubmit = useCallback(
    (formData: FormData) => {
      mutation.mutate({ leadContributor: formData, representativeId: representative?.id ?? '' })
    },
    [mutation, representative?.id]
  )

  return (
    <Modal className="modal--form" isOpen={true} onClose={onClose} title={t('lead_contributors.modal.create.title')}>
      <Form error={error} onSubmit={onSubmit}>
        <Button
          type="submit"
          className="button--solid button--primary button--medium"
          tracking={[CLICKS.leadContributorSave]}
        >
          <span>{t('lead_contributors.modal.create.submit')}</span>
        </Button>
        <Button className="button--solid button--secondary button--medium" onClick={onClose}>
          <span>{t('common.cancel')}</span>
        </Button>
      </Form>
    </Modal>
  )
}
