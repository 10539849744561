import { BosAssignment } from 'lib/oleenApi'
import React from 'react'
import { useWizard } from 'react-use-wizard'
import { BOSAssignmentInstructionForm } from './bosAssignmentInstructionForm'
import { t } from 'utils/i18n'
import { CLICKS, track } from 'helpers/tracking'

interface InstructionsStepProps {
  setBosAssignment: React.Dispatch<React.SetStateAction<BosAssignment>>
  bosAssignment: BosAssignment
}

export const InstructionsStep: React.FC<InstructionsStepProps> = ({ setBosAssignment, bosAssignment }) => {
  const { nextStep, previousStep } = useWizard()

  const action = (bosAssignment: BosAssignment) => {
    setBosAssignment(prev => {
      return {
        ...prev,
        ...bosAssignment,
      }
    })

    const {
      allowToContactClient,
      loanOfferChecked,
      bankContactGiven,
      notaryContactGiven,
      additionalInformation,
      opportunityId,
    } = bosAssignment

    track(CLICKS.nextStepBosAssignment, {
      step: 'instructions',
      ...{
        allowToContactClient,
        loanOfferChecked,
        bankContactGiven,
        notaryContactGiven,
        additionalInformation,
        opportunityId,
      },
    })
    nextStep()
  }

  const onCancel = () => {
    track(CLICKS.previousStepBosAssignment, { step: 'instructions', opportunityId: bosAssignment.opportunityId })
    previousStep()
  }

  return (
    <div className="text-xs">
      <p>{t('opportunity.bos_assignment_modal.steps.instructions.introduction')}</p>
      <BOSAssignmentInstructionForm action={action} onCancel={onCancel} bosAssignment={bosAssignment} />
    </div>
  )
}
