import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOleenApi } from 'contexts/oleenApi'
import { toast } from 'helpers/toast'
import { CLICKS } from 'helpers/tracking'
import { Opportunity } from 'lib/oleenApi'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { Button } from 'ui/buttons/button'
import { t } from 'utils/i18n'

export const BOSAssignmentButton: React.FC<{ opportunity: Opportunity }> = ({ opportunity }) => {
  const { oleenApi } = useOleenApi()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const stopBosAssignment = useMutation({
    mutationFn: (bosAssignmentId: string) => {
      return oleenApi.opportunityBosAssignments().delete(bosAssignmentId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mortgageOpportunity', opportunity.id] })
      queryClient.invalidateQueries({ queryKey: ['mortgageOpportunities'] })
      toast.success(t('opportunity.bos_assignment_button.stop_success'), undefined, { autoClose: 2000 })
    },
    onError: () => toast.error(t('opportunity.bos_assignment_button.stop_error')),
  })

  return (
    <>
      {!opportunity.isBOSAssignmentActive && (
        <Button
          className="button--outline button--secondary button--small"
          tracking={[CLICKS.bosAssignmentOpen]}
          onClick={() => navigate(`${ROUTES.OPPORTUNITY_BOS_ASSIGNMENT}`)}
          disabled={!opportunity.isBOSAssignmentAvailable}
        >
          {t('opportunity.bos_assignment_button.open')}
        </Button>
      )}
      {opportunity.isBOSAssignmentActive && (
        <Button
          className="button--outline button--secondary button--small"
          tracking={[CLICKS.bosAssignmentStop]}
          title={t('opportunity.bos_assignment_button.stop_tooltip')}
          onClick={() => {
            if (opportunity.bosAssignmentId) {
              stopBosAssignment.mutate(opportunity.bosAssignmentId)
            }
          }}
          disabled={opportunity.bosAssignmentState === 'accepted'}
        >
          {t('opportunity.bos_assignment_button.stop')}
        </Button>
      )}
    </>
  )
}
