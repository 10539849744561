import React, { useState } from 'react'
import { t } from 'utils/i18n'

import { LabelledCardHorizontalBody as HCardBody } from 'ui/legacy/molecules/card/card.styled'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { Opportunity } from 'lib/oleenApi'
import classNames from 'classnames'

interface AnnotationCardProps {
  opportunity: Opportunity
  className?: string
}

const ExpandIcon: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <button onClick={onClick}>
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
    >
      <path
        d="M17.5 7.65833V3.83333C17.5 3.375 17.125 3 16.6667 3H12.8417C12.1 3 11.725 3.9 12.25 4.425L13.575 5.75L5.24167 14.0833L3.91667 12.7583C3.4 12.2333 2.5 12.6 2.5 13.3417V17.1667C2.5 17.625 2.875 18 3.33333 18H7.15833C7.9 18 8.275 17.1 7.75 16.575L6.425 15.25L14.7583 6.91667L16.0833 8.24167C16.6 8.76667 17.5 8.4 17.5 7.65833Z"
        fill="currentColor"
      />
    </svg>
  </button>
)

export const AnnotationCard: React.FC<AnnotationCardProps> = ({ opportunity, className }) => {
  const [iframeExpanded, setIframeExpanded] = useState<boolean>(false)

  return (
    <LabelledCard
      icon={CARD_ICON.AnnotationIcon}
      title={t('opportunity.notes_title')}
      className={className}
      headerRightElement={<ExpandIcon onClick={() => setIframeExpanded(!iframeExpanded)} />}
    >
      <HCardBody>
        <iframe
          className={classNames('w-full transition-all', iframeExpanded ? 'h-[800px]' : 'h-24')}
          src={t('url.notes_iframe', { projectId: opportunity.projectId })}
        ></iframe>
      </HCardBody>
    </LabelledCard>
  )
}
