import { useForm } from '@tanstack/react-form'
import { selectInputProps, textInputProps } from 'helpers/tanstackForm'
import { BusinessPartnerErrorAttribute, BusinessPartnerErrorType } from 'lib/oleenApi'
import { BUSINESS_TYPES } from 'lib/oleenApi/services/businessPartnersService'
import React, { PropsWithChildren } from 'react'
import { SelectInput } from 'ui/dropdowns/selectInput'
import { TextInput } from 'ui/inputs/textInput'
import { TanstackFormInput } from 'ui/misc/tanstackFormInput'
import { t } from 'utils/i18n'

enum Fields {
  name = 'name',
  businessType = 'businessType',
}

export type FormData = {
  [key in Fields]: string
}

export type FormError = Partial<Record<BusinessPartnerErrorAttribute, BusinessPartnerErrorType>>

const options = BUSINESS_TYPES.map(value => ({
  value,
  label: t(`business_partners.business_type.${value}`),
  type: 'text' as const,
}))

type FormProps = PropsWithChildren<{
  error?: FormError
  onSubmit: (formData: FormData) => void
}>

export const Form: React.FC<FormProps> = ({ onSubmit, error, children }) => {
  const { Field, handleSubmit } = useForm({
    defaultValues: {
      [Fields.name]: '',
      [Fields.businessType]: '',
    },
    onSubmit: ({ value }) => onSubmit(value as FormData),
  })
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        handleSubmit()
      }}
    >
      <Field name={Fields.name}>
        {field => (
          <TanstackFormInput
            className="w-full"
            label={t('business_partners.form.name.label')}
            field={field}
            apiError={error?.name}
          >
            <TextInput placeholder={t('business_partners.form.name.placeholder')} {...textInputProps(field, error)} />
          </TanstackFormInput>
        )}
      </Field>

      <Field name={Fields.businessType}>
        {field => (
          <TanstackFormInput
            className="w-full"
            label={t('business_partners.form.type.label')}
            field={field}
            apiError={error?.name}
          >
            <SelectInput dropdownClassName="w-full" {...selectInputProps(field, [options])} />
          </TanstackFormInput>
        )}
      </Field>
      {children}
    </form>
  )
}
