import classNames from 'classnames'
import { camelToSnakeCase } from './casing'

// To be used with tanstack/react-form
export const textInputProps = (field: any, error?: any) => {
  const hasError =
    field.state.meta.errors.length || (error && (error[field.name] || error[camelToSnakeCase(field.name)]))

  const props = {
    className: classNames('max-md:textInput--medium', { 'textInput--error': hasError }),
    id: field.name,
    value: field.state.value ?? '',
    onChange: field.handleChange,
    onBlur: field.handleBlur,
  }
  return props
}

export const textAreaProps = (field: any, className: string) => ({
  className: classNames('max-md:textArea--medium', className, { 'textArea--error': field.state.meta.errors.length }),
  id: field.name,
  value: field.state.value,
  onChange: field.handleChange,
  onBlur: field.handleBlur,
})

export const selectInputProps = (field: any, options: any[], className: string = '') => ({
  className: classNames('max-md:selectInput--medium selectInput--small', className, {
    'selectInput--error': field.state.meta.errors.length,
  }),
  id: field.name,
  value: field.state.value,
  onChange: field.handleChange,
  onBlur: field.handleBlur,
  options,
})
