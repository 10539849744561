import { CheckCircleIcon, ClockIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { isBOSAlertStatus } from 'helpers/opportunity'
import { t } from 'i18next'
import { Opportunity } from 'lib/oleenApi'
import React, { useMemo } from 'react'
import { Banner } from 'ui/banners/banner'

interface BOSAssignmentInfosProps {
  opportunity: Opportunity
}

export const BOSAssignmentInfos: React.FC<BOSAssignmentInfosProps> = ({ opportunity }) => {
  const isDisplayingBOSInstructions = useMemo<boolean>(() => {
    return (
      opportunity.isWithinBOSAssignmentWindow &&
      opportunity.isWithinBOSAssignmentState &&
      !opportunity.isBOSAssignmentActive
    )
  }, [
    opportunity.isWithinBOSAssignmentWindow,
    opportunity.isWithinBOSAssignmentState,
    opportunity.isBOSAssignmentActive,
  ])

  if (opportunity.isOnlyFactured || opportunity.bosAssignmentState === 'rejected') {
    return (
      <Banner className="alert--neutral alert--small">
        <InformationCircleIcon className="h-5 w-5" />
        {t('opportunity.bos_assignment_infos.facturation')}
      </Banner>
    )
  }

  return (
    <>
      {opportunity.bosAssignmentState === 'pending' && (
        <Banner className="alert--neutral alert--small">
          {<ClockIcon className="h-5 w-5" />}
          {t('opportunity.bos_assignment_infos.pending')}
        </Banner>
      )}
      {opportunity.bosAssignmentState === 'accepted' && (
        <Banner className="alert--confirmation alert--small">
          {<CheckCircleIcon className="h-5 w-5" />}
          {t('opportunity.bos_assignment_infos.accepted', { assigned_bos: opportunity.assignedBos })}
        </Banner>
      )}
      {isDisplayingBOSInstructions && isBOSAlertStatus(opportunity.status) && (
        <Banner className="alert--warning alert--small">
          <InformationCircleIcon className="h-5 w-5" />
          <div>
            <p>{t('opportunity.bos_assignment_infos.instructions.one')}</p>
            <p>{t('opportunity.bos_assignment_infos.instructions.two')}</p>
          </div>
        </Banner>
      )}
    </>
  )
}
