export const ROUTES = {
  OPPORTUNITIES: '/opportunities',
  OPPORTUNITY: '/opportunities/:opportunityId',
  OPPORTUNITY_PATH: ':opportunityId',
  OPPORTUNITY_DROP: 'drop',
  OPPORTUNITY_CREATE_TASK: 'create-task',
  OPPORTUNITY_STONLY_FORM: 'stonly-form',
  OPPORTUNITY_INSURANCE: 'insurance',
  OPPORTUNITY_CREATE: 'new',
  OPPORTUNITY_NEW_CONSULTANCY_CONTRACT: 'new-consultancy-contract',
  OPPORTUNITY_NEW_MANDATE: 'new-mandate',
  OPPORTUNITY_NEW_FEASABILITY_CERTIFICATE: 'new-feasability-certificate',
  OPPORTUNITY_EMAILS: 'emails',
  OPPORTUNITY_NEW_LEAD_CONTRIBUTOR: 'new-lead-contributor',
  DROP_OPPORTUNITY: '/opportunities/:opportunityId/drop',
  ACCOUNT: '/account',
  ADDON: 'addons/:addonName',
  ADDON_SUBSCRIPTION_CONFIRMATION: 'addons/:addonName/confirmation',
  PRODUCTION_DASHBOARD: '/production/dashboard',
  ORG_MEMBERS: '/org/members',
  HOME: '/home',
  USEFUL_LINKS: 'useful-links',
  // FIXME : we don't change the route below not to break the iframe in SF, as this route will disappear anyway
  MORTGAGE_FORM_STANDALONE: '/mortgage-form',
  MORTGAGE_FORM: '/opportunity-creation',
  ROADMAP: '/roadmap',
  LOGOUT: '/logout',
  LEAD_CONTRIBUTORS: '/lead-contributors',
  LEAD_CONTRIBUTOR_PATH: ':leadContributorId',
  LEAD_CONTRIBUTOR_CREATE: 'new',
  BUSINESS_PARTNER_CREATE: 'new-business-partner',
  BUSINESS_PARTNER_EDIT: 'business-partner/:businessPartnerId',
  TASKS: '/tasks',
  TASK_PATH: ':taskId',
  TASK_CREATE: 'create',
  TASK_EDIT: 'edit',
  TASK_SHOW: 'show',
  TASK_ADD_TO_CALENDAR: 'addToCalendar',
  BACK: '..',
}
