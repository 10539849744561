import { Form, FormData } from 'components/businessPartners/createBusinessPartnerForm'
import { toast } from 'helpers/toast'
import React, { useCallback } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { CLICKS } from 'helpers/tracking'
import { Modal } from 'ui/modals/modal'
import { useQueryClient } from '@tanstack/react-query'
import { useCreateBusinessPartnerMutation } from 'helpers/queries'
import { useFormError } from 'helpers/errorHandler'

type NewBusinessPartnerModalProps = {
  onClose: () => void
}

export const NewBusinessPartnerModal: React.FC<NewBusinessPartnerModalProps> = ({ onClose }) => {
  const queryClient = useQueryClient()
  const { error, onError } = useFormError()

  const mutation = useCreateBusinessPartnerMutation({
    queryClient,
    onSuccess: () => {
      toast.success(t('business_partners.toasts.creation.success'))
      onClose()
    },
    onError: error => {
      toast.error(t('business_partners.toasts.creation.error'))
      onError(error)
    },
  })

  const onSubmit = useCallback(
    (payload: FormData) => {
      mutation.mutate(payload)
    },
    [mutation]
  )

  return (
    <Modal className="modal--form" isOpen={true} onClose={onClose} title={t('business_partners.modal.create.title')}>
      <Form error={error} onSubmit={onSubmit}>
        <Button
          type="submit"
          className="button--solid button--primary button--medium"
          tracking={[CLICKS.businessPartnerSave]}
        >
          <span>{t('business_partners.modal.create.submit')}</span>
        </Button>
        <Button className="button--solid button--secondary button--medium" onClick={onClose}>
          <span>{t('common.cancel')}</span>
        </Button>
      </Form>
    </Modal>
  )
}
