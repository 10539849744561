import React, { Suspense } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { EditBusinessPartnerModal } from 'components/businessPartners/editBusinessPartnerModal'

export const EditBusinessPartnerPage: React.FC = () => {
  const navigate = useNavigate()
  const businessPartnerId = useParams().businessPartnerId

  // TODO: how to avoid this check
  if (!businessPartnerId) return <></>

  return (
    <Suspense fallback=<></>>
      <EditBusinessPartnerModal onClose={() => navigate(-1)} businessPartnerId={businessPartnerId} />
    </Suspense>
  )
}
