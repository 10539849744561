import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { TextInput } from 'ui/inputs/textInput'
import { useRepresentativeContext } from 'contexts/representative'
import { CLICKS } from 'helpers/tracking'
import { LeadContributorsTable } from 'components/leadContributors/table'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLeadContributorsInfiniteQuery, useLeadContributorsTotalQuery } from 'helpers/queries'
import { useDebouncedState } from 'hooks/debouncedState'
import { ROUTES } from 'router/routes'

export const LeadContributorsPage: React.FC = () => {
  const { representative } = useRepresentativeContext()
  const navigate = useNavigate()
  const [search, setSearch] = useDebouncedState('', 200)
  const leadContributorsTotalQuery = useLeadContributorsTotalQuery()
  const leadContributorsQuery = useLeadContributorsInfiniteQuery(search)

  return (
    <>
      <div className="flex flex-col items-start flex-1 self-stretch divide-y h-full">
        <header className="flex p-6 pb-4 flex-col items-start gap-4 self-stretch antialiased">
          <div className="flex justify-between items-center self-stretch">
            <div className="flex flex-1 items-center gap-2">
              <h1
                className="text-gray-900 text-3xl font-bold font-inter leading-9
"
              >
                {t('lead_contributors.page_title')}
              </h1>
              <p className="text-gray-500 text-3xl font-normal font-inter leading-9">
                {leadContributorsTotalQuery.data}
              </p>
            </div>
            <div className="flex items-center gap-4">
              <Button
                tracking={[CLICKS.leadContributorAdd]}
                className="button--solid button--primary button--small"
                onClick={() => navigate(`${ROUTES.LEAD_CONTRIBUTORS}/${ROUTES.LEAD_CONTRIBUTOR_CREATE}`)}
              >
                {t('lead_contributors.new')}
              </Button>
              <Button
                tracking={[CLICKS.businessPartnerAdd]}
                className="button--solid button--primary button--small"
                onClick={() => navigate(`${ROUTES.LEAD_CONTRIBUTORS}/${ROUTES.BUSINESS_PARTNER_CREATE}`)}
              >
                {t('business_partners.new')}
              </Button>
            </div>
          </div>
        </header>
        <section className="flex px-6 py-2 flex-col items-start gap-6 flex-1 grow self-stretch overflow-scroll">
          <div className="flex flex-col items-start self-stretch overflow-scroll">
            <div className="flex py-4 items-start gap-4 self-stretch sticky">
              <TextInput
                className="textInput--small w-[350px]"
                placeholder={t('common.search')}
                Icon={MagnifyingGlassIcon}
                onChange={setSearch}
              />
            </div>
            <div className="flex flex-col self-stretch overflow-scroll">
              {representative && (
                <LeadContributorsTable
                  leadContributors={leadContributorsQuery?.data?.pages.flatMap(page => page.items) ?? []}
                  representative={representative}
                  fetchNextPage={leadContributorsQuery.fetchNextPage}
                />
              )}
            </div>
          </div>
        </section>
      </div>
      <Outlet />
    </>
  )
}
