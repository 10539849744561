import { useSuspenseQuery } from '@tanstack/react-query'
import { OleenApi } from 'lib/oleenApi'
import React, { PropsWithChildren } from 'react'
import { useSearchParams } from 'react-router-dom'

export const Impersonation: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [searchParams] = useSearchParams()
  const email = searchParams.get('impersonation')

  /**
   * Using suspense is necessary to delay the rendering of the children until
   * the query is resolved.  Precisely, we want to delay the lodaing of the
   * RepresentativeContextProvider. We need to make sure the impersonation is
   * done before loading the representative
   */
  useSuspenseQuery({
    queryKey: ['impersonation'],
    queryFn: async () => {
      if (email) {
        const client = new OleenApi()
        client.setAuthToken(localStorage.getItem('token') || '')
        await client.request({ url: '/auth/impersonations/foo', method: 'DELETE' })
        return client.request({ url: '/auth/impersonations', method: 'POST', data: { email } })
      }
      return null
    },
  })

  return <>{children}</>
}
