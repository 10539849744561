import { PhoneNumberUtil } from 'google-libphonenumber'
import { ApiError } from 'lib/oleenApi/apiError'
import { useState } from 'react'
import { EMPTY_FIELD_VALUE } from 'types/input'
import { t } from 'utils/i18n'

// check that some value could be an actual date
export const isDateValid = (value: string): boolean => {
  if (value === EMPTY_FIELD_VALUE) return true
  if (value.length < 10) return false

  try {
    new Date(value).toISOString()
    return true
  } catch {
    return false
  }
}

const phoneUtil = PhoneNumberUtil.getInstance()

export const isPhoneNumberValid = (phone: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

export enum errorHandlerMode {
  NON_NULLABLE = 'NON_NULLABLE',
  NON_NULLABLE_NO_ERROR_TEXT = 'NON_NULLABLE_NO_ERROR_TEXT',
  PERCENT = 'PERCENT',
  EMAIL = 'EMAIL',
  EMAIL_NULLABLE = 'EMAIL_NULLABLE',
  PHONE = 'PHONE',
  NUMBER = 'NUMBER',
  IS_AVAILABLE_OPTION = 'IS_AVAILABLE_OPTION',
  DATE = 'DATE',
}

export type Validation = { type: errorHandlerMode; options?: { list?: string[] } }

export const emailValidator =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const telValidator = /^((\+)33|0)[1-9](\d{2}){4}$/
export const numberValidator = /^[0-9]*\.?[0-9]?[0-9]?$/
export const percentValidator = /^([0-9]{1,2}){1}(\.[0-9]{1,2})?$/

export class ErrorHandler {
  validations: Validation[] = []

  constructor({ validations }: { validations: Validation[] }) {
    this.validations = validations
  }

  hasError(value: string): boolean {
    const errors = this.validations.map(validation => {
      switch (validation.type) {
        case errorHandlerMode.NON_NULLABLE:
          return !value || value === EMPTY_FIELD_VALUE
        case errorHandlerMode.NON_NULLABLE_NO_ERROR_TEXT:
          return !value || value === EMPTY_FIELD_VALUE
        case errorHandlerMode.EMAIL:
          return !emailValidator.test(value)
        case errorHandlerMode.EMAIL_NULLABLE:
          return value !== null && value !== '' && !emailValidator.test(value)
        case errorHandlerMode.PHONE:
          return !isPhoneNumberValid(value)
        case errorHandlerMode.NUMBER:
          return !numberValidator.test(value)
        case errorHandlerMode.PERCENT:
          return !percentValidator.test(value)
        case errorHandlerMode.IS_AVAILABLE_OPTION:
          return validation?.options?.list && !validation.options.list.includes(value)
        case errorHandlerMode.DATE:
          return !isDateValid(value)
        default:
          return false
      }
    })
    return !!errors.includes(true)
  }

  getErrorsHasText(value: string): string {
    const errors = this.validations.map(validation => {
      switch (validation.type) {
        case errorHandlerMode.NON_NULLABLE:
          return !value || value === EMPTY_FIELD_VALUE ? t('common.errors.field.required') : null
        case errorHandlerMode.EMAIL:
          return !emailValidator.test(value) ? t('common.errors.field.invalid_email') : null
        case errorHandlerMode.EMAIL_NULLABLE:
          return value !== null && value !== '' && !emailValidator.test(value)
            ? t('common.errors.field.invalid_email')
            : null
        case errorHandlerMode.PHONE:
          return !isPhoneNumberValid(value) ? t('common.errors.field.invalid_phone') : null
        case errorHandlerMode.NUMBER:
          return !numberValidator.test(value) ? t('common.errors.field.invalid_number') : null
        case errorHandlerMode.PERCENT:
          return !percentValidator.test(value) ? t('common.errors.field.invalid_percent') : null
        case errorHandlerMode.IS_AVAILABLE_OPTION:
          return validation?.options?.list && !validation.options.list.includes(value)
            ? t('forms.errors.not_matching')
            : null
        case errorHandlerMode.DATE: {
          return isDateValid(value) ? null : t('common.errors.field.invalid_date')
        }
        default:
          return null
      }
    })

    return errors.filter(error => !!error).join('\n') || ''
  }
}

export const useFormError = () => {
  const [error, setError] = useState({})

  const onError = (error: Error) => {
    if (error instanceof ApiError) {
      setError(error.format())
    }
  }
  return { error, onError }
}
