import { MortgageDealLossReasonType } from 'types/mortgageDealLoss'

export type SignatureBase = {
  signed_at: string
}

/*
 * RESPONSE TYPES
 */

interface BOSAssignmentResponse {
  id: string
  state: string
  assigned_bos: string | null
}

interface FinancingResponse {
  application_fees: number | null
  bridge_amount: number | null
  bank_commission: number | null
  contribution_rate: number | null
  contribution_amount: number | null
  debt_rate_during_bridge: null
  debt_rate: number | null
  duration_as_month: number | null
  guaranty_fees: number | null
  has_bridge: boolean
  id: string
  monthly_payment: number | null
  rate: number | null
  total_amount: number | null
  total_fees: number | null
}

export interface InterrogationResponse {
  bank_contact_name: string | null
  bank_group: string
  bank_response: {
    created_at: string | null
    status: BankResponseStatus | null
    rate: number | null
    duration: string | null
  } | null
  bank_response_expected: boolean
  bank_response_expected_at: string | null
  finspot_created_at: string
  finspot_id: string
  id: string
  requested_amount: number | null
  requested_duration: number | null
  requested_rate: number | null
  stage: InterrogationStage
  sent_at: string | null
  finspot_updated_at: string
}

interface MortgagorResponse {
  birthdate: string | null
  civil_status: string | null
  created_at: string
  email: string | null
  finspot_id: string
  first_name: string | null
  home_address: AddressResponse
  id: string
  job_title: string | null
  last_name: string | null
  phone: string | null
  rank: number
  updated_at: string
  yearly_income: number | null
  yearly_weighted_income: number | null
}

interface ProjectResponse {
  agency_fees: number | null
  agreement_sign_date: string | null
  build_price: null | number
  brokerage_fees: number | null
  conditions_precedent_lift_date: string | null
  created_at: string
  documents_completion_rate: number | null
  financing: FinancingResponse | null
  finspot_id: string
  furniture_price: null | number
  good_country: string
  good_property_kind: PropertyKind
  good_property_price: number
  good_address: AddressResponse
  good_usage: GoodUsage
  id: string
  interrogations: InterrogationResponse[]
  ira: number | null
  kind: ProjectKind
  land_price: number | null
  latest_file_upload_at: string | null
  maturity: Maturity
  mortgagors: MortgagorResponse[]
  notary_fees: number | null
  price_before_fees: number | null
  remaining_principal: number | null
  sale_date: string | null
  storage_url: string | null
  updated_at: string
  works_price: number | null
  total_costs_amount: number
}

export interface SignatureResponse {
  signed_at: string | null
  email: string
  is_representative: boolean
}

interface MandateResponse {
  id: string
  status: MANDATE_STATUS
  updated_at: string
  signatures: SignatureResponse[]
}

interface LossResponse {
  reason: MortgageDealLossReasonType
  competitor_name: string | null
  loan_duration: number | null
  loan_rate: number | null
  comment: string | null
}

interface AddressResponse {
  street: string | null
  city: string | null
  zipcode: string | null
  country: string | null
}

export interface OpportunityResponse {
  id: string
  created_at: string
  status: OPPORTUNITY_STATUS
  subtitle: string | null
  bank_appointment_on: string | null
  bank_contact: string | null
  bank_offer_accepted_on: string | null
  bank_offer_received_on: string | null
  bos_files_uploaded: boolean
  bos_assignment: BOSAssignmentResponse | null
  brokerage_fees: number | null
  lead_contributor_id: string | null
  lead_contributor_name: string | null
  prescription_fees: number | null
  prescription_type: SourceType | null
  representative_id: string
  updated_at: string
  project: ProjectResponse
  mandate: MandateResponse
  loss: LossResponse | null
  yuccanlead_id: string | null
  remaining_tasks_count: number
  is_ready_to_bos: boolean
  bypass_bos_validation: boolean
  der_sent_at: string | null
  der_email_status: string | null
  assigned_bos: string | null
}

/*
 * INTERNAL TYPES
 */

export enum OPPORTUNITY_STATUS {
  A_JOINDRE = 'to_contact',
  RELANCE_DOC = 'pending_doc',
  EN_MONTAGE = 'in_progress',
  ENVOYE_BANQUE = 'sent_to_bank',
  ACCORD_BANQUE = 'got_bank_agreement',
  ACCORD_CLIENT = 'got_customer_agreement',
  PLANNED_BANK_MEETING = 'bank_meeting_scheduled',
  EDITED_OFFER = 'offer_edited',
  SIGNED_OFFER = 'offer_signed',
  FONDS_DEBLOQUES = 'funds_released',
  PERDU = 'lost',
}

export enum MANDATE_STATUS {
  SIGNED = 'signed',
  CANCELED = 'canceled',
  CREATED = 'created',
}

export enum Maturity {
  search_started = 'search_started',
  offer_made = 'offer_made',
  offer_accepted = 'offer_accepted',
  under_study = 'under_study',
  sale_agreement_signed = 'sale_agreement_signed',
  null = 'null',
}

export enum GoodUsage {
  primary_residence = 'primary_residence',
  rental_investment = 'rental_investment',
  secondary_residence = 'secondary_residence',
  null = 'null',
}

export enum ProjectKind {
  purchase = 'purchase',
  renegotiation = 'renegotiation',
}

export enum PropertyKind {
  ccmi = 'ccmi',
  scpi = 'scpi',
  vefa = 'vefa',
  construction = 'construction',
  land = 'land',
  land_construction = 'land_construction',
  new = 'new',
  old = 'old',
  null = 'null',
}

export enum SourceType {
  pretto = 'pretto',
  profesional = 'profesional',
  patronage = 'patronage',
  none = 'none',
  website = 'website',
}

export enum DisabledSourceType {
  pretto = 'pretto',
  website = 'website',
}

export enum InterrogationStage {
  client_approved = 'client_approved',
  bank_approved = 'bank_approved',
  refused = 'refused',
  dropped = 'dropped',
  sent = 'sent',
  pending = 'pending',
  sending_in_progress = 'sending_in_progress',
  sending_error = 'sending_error',
  approval_denied = 'approval_denied',
  approval_pending = 'approval_pending',
}

export type SignatureRoleEmail = {
  email: string
  signedAt: string | null
}

export type Signature = {
  mortgagor?: SignatureRoleEmail
  representative?: SignatureRoleEmail
}

export type Opportunity = {
  id: string
  createdAt: string
  projectId: string | null
  status: OPPORTUNITY_STATUS
  subtitle: string | null
  brokerageFees: number
  bankCommission?: number | null
  bankAppointmentOn?: string | null
  bankOfferReceivedOn?: string | null
  bankOfferAcceptedOn?: string | null
  bankContact?: string | null
  derSentAt?: string | null
  derEmailStatus?: string | null
  bosFilesUploaded: boolean
  remainingTasksCount: number

  // BOSAssignment
  bosAssignmentId?: string
  bosAssignmentState?: string
  assignedBos?: string | null
  isWithinBOSAssignmentWindow: boolean
  isBOSAssignmentActive: boolean
  isOnlyFactured: boolean
  isMissingBOSInfos: boolean
  bypassBosValidation: boolean
  isBOSAssignmentAvailable: boolean
  isWithinBOSAssignmentState: boolean

  // Docs
  storageUrl: string | null
  docsCompletion: number
  docsCompletionLastUpdateDate?: string | null

  // Opportunity Source
  sourceAmountPaid?: number | null
  sourceType?: SourceType | null
  leadContributorId?: string | null
  leadContributorName?: string | null

  // Contacts
  hasCoMortgagor: boolean
  mortgagorFirstName: string | null
  mortgagorLastName: string | null
  mortgagorAddress?: Address | null
  mortgagorEmail: string | null
  mortgagorPhone: string | null
  mortgagorJobTitle?: string | null
  mortgagorBirthdate?: string | null
  coMortgagorFirstName?: string | null
  coMortgagorLastName?: string | null
  coMortgagorAddress?: Address | null
  coMortgagorEmail: string | null
  coMortgagorPhone: string | null
  coMortgagorJobTitle?: string | null
  coMortgagorBirthdate?: string | null

  // Project
  maturity: Maturity
  goodUsage: GoodUsage

  propertyKind: PropertyKind
  goodAddress: Address
  projectKind: ProjectKind
  contribution?: number | null
  conditionsPrecedentLiftDate?: string | null
  saleDate?: string | null
  agreementSignDate?: string | null
  propertyPrice?: number
  priceBeforeFees?: number | null

  // Financing
  hasFinancing: boolean
  loanAmount?: number | null
  loanDuration?: number | null
  bridgeValue?: number | null
  mainLoansRate?: number | null // TODO Type to be confirmed
  monthlyLoanRepayment?: number | null
  indebtednessRate?: number | null
  totalFees?: number | null
  totalProjectCost?: number | null

  // Mandate
  mandateId?: string | null
  mandateStatus?: MANDATE_STATUS | null
  mandateStatusUpdatedAt?: string // this is a ISO 8601 Date string -> we need to harmonise date formats returned by the API
  mandateSignatures?: Signature[] | null

  // Deal Loss
  last_mortgage_deal_loss?: MortgageDealLoss | null

  // Interrogations
  interrogations?: Array<Interrogation>

  // external services
  yuccanleadId: string | null

  // methods
  areBankResponsesExpected: boolean
  isReadyToBOS: boolean
}

export type BankResponseStatus = 'denied' | 'dropped' | 'sales_agreement' | 'risk_agreement'

export type Interrogation = {
  id: string
  finspotId: string
  bankContactName: string | null
  bankGroup: string
  bankResponseStatus: BankResponseStatus | null
  bankResponseCreatedAt: string | null
  isBankResponseExpected: boolean
  stage: InterrogationStage
  loanAmount: number | null
  loanDuration: number | null
  loanRate: number | null
  createdAt: string
  updatedAt: string
  sentAt: string | null
}

export type MortgageDealLoss = {
  reason: MortgageDealLossReasonType
  competitor_name: string | null
  loan_duration: number | null
  loan_rate: number | null
  comment: string | null
}

export interface Address {
  street: string | null
  zipcode: string | null
  country: string | null
  city: string | null
}
