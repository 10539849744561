import { BosAssignment, OpportunityFile } from 'lib/oleenApi'
import React from 'react'
import { useWizard } from 'react-use-wizard'
import { BOSAssignmentFileUploadForm } from './bosAssignmentFileUploadForm'
import { t } from 'utils/i18n'
import { CLICKS, track } from 'helpers/tracking'

interface SaleAgreementStepProps {
  setBosAssignment: React.Dispatch<React.SetStateAction<BosAssignment>>
  bosAssignment: BosAssignment
}

export const SaleAgreementStep: React.FC<SaleAgreementStepProps> = ({ setBosAssignment, bosAssignment }) => {
  const { nextStep, previousStep } = useWizard()

  const action = (file: OpportunityFile | null) => {
    if (file) {
      setBosAssignment(prev => {
        return {
          ...prev,
          files: {
            ...prev.files,
            saleAgreement: file,
          },
        }
      })
    }

    track(CLICKS.nextStepBosAssignment, {
      step: 'sale_agreement',
      file: file?.filename,
      alreadyUpload: file === null,
      opportunityId: bosAssignment.opportunityId,
    })
    nextStep()
  }

  const onCancel = () => {
    track(CLICKS.previousStepBosAssignment, { step: 'sale_agreement', opportunityId: bosAssignment.opportunityId })
    previousStep()
  }

  return (
    <div className="text-xs">
      <p>
        <b>{t('opportunity.bos_assignment_modal.steps.sale_agreement.warning')}</b>
      </p>
      <BOSAssignmentFileUploadForm
        action={action}
        onCancel={onCancel}
        mandatory={true}
        fileType="sale_agreement"
        defaultValue={bosAssignment?.files.saleAgreement?.data}
      />
    </div>
  )
}
