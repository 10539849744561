import { BosAssignment, OpportunityFile } from 'lib/oleenApi'
import { BOSAssignmentFileUploadForm } from './bosAssignmentFileUploadForm'
import { useWizard } from 'react-use-wizard'
import React from 'react'
import { t } from 'utils/i18n'
import { CLICKS, track } from 'helpers/tracking'

interface BankAgreementStepProps {
  setBosAssignment: React.Dispatch<React.SetStateAction<BosAssignment>>
  bosAssignment: BosAssignment
  closeModal: () => void
}

export const BankAgreementStep: React.FC<BankAgreementStepProps> = ({
  setBosAssignment,
  bosAssignment,
  closeModal,
}) => {
  const { nextStep } = useWizard()

  const action = (file: OpportunityFile | null) => {
    if (file) {
      setBosAssignment(prev => {
        return {
          ...prev,
          files: {
            ...prev.files,
            bankAgreement: file,
          },
        }
      })
    }

    track(CLICKS.nextStepBosAssignment, {
      step: 'bank_agreement',
      file: file?.filename,
      alreadyUpload: file === null,
      opportunityId: bosAssignment.opportunityId,
    })
    nextStep()
  }

  const onCancel = () => {
    track(CLICKS.cancelBosAssignment, { step: 'bank_agreement', opportunityId: bosAssignment.opportunityId })
    closeModal()
  }

  return (
    <div className="text-xs">
      <p>{t('opportunity.bos_assignment_modal.steps.bank_agreement.introduction')}</p>
      <p>
        <b>{t('opportunity.bos_assignment_modal.steps.bank_agreement.warning')}</b>
      </p>
      <BOSAssignmentFileUploadForm
        action={action}
        onCancel={onCancel}
        mandatory={true}
        fileType="bank_agreement"
        defaultValue={bosAssignment?.files.bankAgreement?.data}
      />
    </div>
  )
}
