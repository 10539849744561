import React from 'react'

interface ILabelledCardLayoutProps {
  className?: string
}

export const CardContainer = ({ className, children }: ILabelledCardLayoutProps & { children: React.ReactNode }) => {
  return <div className={`border border-gray-300 ${className}`}>{children}</div>
}

export const LabelledCardHead = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="bg-gray-100 rounded-t-lg border-gray-200 border-b flex justify-between p-3 font-bold text-sm h-fit text-gray-800">
      <div className="card_title ml-2">{children}</div>
    </div>
  )
}

export const LabelledCardHorizontalBody = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex p-4">{children}</div>
}

export const LabelledCardVerticalBody = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col text-xs p-4 space-y-1 font-normal leading-5">{children}</div>
}

export const LabelledCardVerticalItem = ({ children }: { children: React.ReactNode }) => {
  return <div className="p-4 border-t text-xs items-center space-y-1">{children}</div>
}

export const CardSignatureRowContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-row flex-1 justify-between line-height-[20px] mt-2">{children}</div>
}

export const CardSignatureRowLabel = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex items-center gap-2 font-normal text-gray-800">{children}</div>
}
