import React from 'react'
import { Card } from 'ui/sections/card'
import { errorHandlerMode } from 'helpers/errorHandler'
import { t } from 'utils/i18n'

import { FormInput } from 'ui/forms/formInput'
import { Representative } from 'lib/oleenApi'

type ParametersCardProps = {
  representative: Representative
}

// TODO: Implement TanstackForm
export const ParametersCard: React.FC<ParametersCardProps> = ({ representative }) => {
  return (
    <Card title={t('account.default_parameters.title')}>
      <div className="card--content">
        <FormInput
          name={'brokerage_fees_default'}
          className="w-fit mb-2"
          defaultValue={representative?.defaultBrokerageFees?.toString()}
          label={t('account.default_parameters.brokerage_fees_default')}
          validations={[{ type: errorHandlerMode.NUMBER }]}
        />
        <FormInput
          name={'brand_email'}
          className="w-fit"
          defaultValue={representative?.brandEmail?.toString()}
          placeholder={t('account.default_parameters.brand_email_placeholder')}
          label={t('account.default_parameters.brand_email')}
          validations={[{ type: errorHandlerMode.EMAIL_NULLABLE }]}
        />
        <div className="text-gray-500 text-xs font-normal antialiased mt-1">
          {t('account.default_parameters.brand_email_infos')}
        </div>
      </div>
    </Card>
  )
}
