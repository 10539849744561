import dayjs from 'dayjs'
import {
  Interrogation,
  InterrogationResponse,
  Opportunity,
  OPPORTUNITY_STATUS,
  OpportunityResponse,
  Signature,
} from './mortgageOpportunitiesService.types'

export const parseOpportunity = (opportunity: OpportunityResponse): Opportunity => {
  // NOTE : Maybe we should move these function on the backend part
  const bosAssignmentId = opportunity.bos_assignment?.id
  const bosAssignmentState = opportunity.bos_assignment?.state
  const interrogations = parseInterrogations(opportunity.project?.interrogations)
  const areBankResponsesExpected = interrogations?.some(({ isBankResponseExpected }) => isBankResponseExpected) ?? false

  // Should also be back validations
  const isWithinBOSAssignmentWindow = opportunity.bank_appointment_on
    ? dayjs().isBefore(dayjs(opportunity.bank_appointment_on).add(6, 'day'))
    : true
  const isWithinBOSAssignmentState =
    Object.values(OPPORTUNITY_STATUS).findIndex(status => status === opportunity.status) <= 6

  // Should be refactored into a BOSMode or BOSStatus enum returned by the API, mode: full/light/none
  const isBOSAssignmentActive =
    !!bosAssignmentId && (bosAssignmentState === 'accepted' || bosAssignmentState === 'pending')
  const isOnlyFactured = !isBOSAssignmentActive && (!isWithinBOSAssignmentState || !isWithinBOSAssignmentWindow)
  const isBOSAssignmentAvailable =
    isWithinBOSAssignmentWindow && isWithinBOSAssignmentState && opportunity.is_ready_to_bos && !isBOSAssignmentActive

  return {
    id: opportunity.id,
    projectId: opportunity.project?.finspot_id,
    status: opportunity.status,
    subtitle: opportunity.subtitle,
    maturity: opportunity.project?.maturity,
    brokerageFees: opportunity.project?.brokerage_fees || 0,
    bankCommission: opportunity.project?.financing?.bank_commission,
    bankAppointmentOn: opportunity.bank_appointment_on,
    bankOfferReceivedOn: opportunity.bank_offer_received_on,
    bankOfferAcceptedOn: opportunity.bank_offer_accepted_on,
    bankContact: opportunity.bank_contact,
    bosFilesUploaded: opportunity.bos_files_uploaded,
    createdAt: opportunity.created_at,
    sourceAmountPaid: opportunity.prescription_fees,
    sourceType: opportunity.prescription_type,
    leadContributorId: opportunity.lead_contributor_id,
    leadContributorName: opportunity.lead_contributor_name,
    hasCoMortgagor: opportunity.project?.mortgagors.length > 1,
    mortgagorFirstName: opportunity.project?.mortgagors[0] ? opportunity.project?.mortgagors[0].first_name : null,
    mortgagorLastName: opportunity.project?.mortgagors[0] ? opportunity.project?.mortgagors[0].last_name : null,
    mortgagorEmail: opportunity.project?.mortgagors[0] ? opportunity.project?.mortgagors[0].email : null,
    mortgagorPhone: opportunity.project?.mortgagors[0] ? opportunity.project?.mortgagors[0].phone : null,
    mortgagorAddress: opportunity.project?.mortgagors[0]
      ? opportunity.project?.mortgagors[0].home_address
      : { city: '', zipcode: '', street: '', country: '' },
    mortgagorJobTitle: opportunity.project?.mortgagors[0] ? opportunity.project?.mortgagors[0].job_title : '',
    mortgagorBirthdate: opportunity.project?.mortgagors[0] ? opportunity.project?.mortgagors[0].birthdate : '',
    coMortgagorFirstName: opportunity.project?.mortgagors[1] ? opportunity.project?.mortgagors[1]?.first_name : null,
    coMortgagorLastName: opportunity.project?.mortgagors[1] ? opportunity.project?.mortgagors[1]?.last_name : null,
    coMortgagorEmail: opportunity.project?.mortgagors[1] ? opportunity.project?.mortgagors[1]?.email : null,
    coMortgagorPhone: opportunity.project?.mortgagors[1] ? opportunity.project?.mortgagors[1]?.phone : null,
    coMortgagorAddress: opportunity.project?.mortgagors[1]
      ? opportunity.project?.mortgagors[1]?.home_address
      : { city: '', zipcode: '', street: '', country: '' },
    coMortgagorJobTitle: opportunity.project?.mortgagors[1] ? opportunity.project?.mortgagors[1]?.job_title : '',
    coMortgagorBirthdate: opportunity.project?.mortgagors[1] ? opportunity.project?.mortgagors[1]?.birthdate : '',
    docsCompletion: opportunity.project?.documents_completion_rate || 0,
    docsCompletionLastUpdateDate: opportunity.project?.latest_file_upload_at,
    projectKind: opportunity.project?.kind,
    goodUsage: opportunity.project?.good_usage,
    goodAddress: opportunity.project?.good_address,
    propertyKind: opportunity.project?.good_property_kind,
    conditionsPrecedentLiftDate: opportunity.project?.conditions_precedent_lift_date,
    saleDate: opportunity.project?.sale_date,
    agreementSignDate: opportunity.project?.agreement_sign_date,
    propertyPrice: opportunity.project?.good_property_price,
    priceBeforeFees: opportunity.project?.price_before_fees,
    contribution: opportunity.project?.financing?.contribution_amount,
    hasFinancing: opportunity.project?.financing !== null && opportunity.project?.financing !== undefined,
    loanAmount: opportunity.project?.financing?.total_amount,
    loanDuration: opportunity.project?.financing?.duration_as_month,
    bridgeValue: opportunity.project?.financing?.bridge_amount,
    mainLoansRate: opportunity.project?.financing?.rate,
    monthlyLoanRepayment: opportunity.project?.financing?.monthly_payment,
    mandateId: opportunity.mandate?.id,
    mandateStatus: opportunity?.mandate?.status || null,
    mandateStatusUpdatedAt: opportunity.mandate?.updated_at, // FIXME parse date
    mandateSignatures: parseMandateSignatures(opportunity),
    indebtednessRate: opportunity.project?.financing?.debt_rate,
    totalFees: opportunity.project?.financing?.total_fees,
    totalProjectCost: opportunity.project?.total_costs_amount,
    last_mortgage_deal_loss: opportunity.loss,
    interrogations,
    storageUrl: opportunity.project?.storage_url,
    yuccanleadId: opportunity.yuccanlead_id,
    remainingTasksCount: opportunity.remaining_tasks_count,
    areBankResponsesExpected,
    isReadyToBOS: opportunity.is_ready_to_bos,
    derSentAt: opportunity.der_sent_at,
    derEmailStatus: opportunity.der_email_status,
    bosAssignmentId: opportunity.bos_assignment?.id,
    bosAssignmentState,
    assignedBos: opportunity.bos_assignment?.assigned_bos,
    isBOSAssignmentActive,
    isWithinBOSAssignmentWindow,
    bypassBosValidation: opportunity.bypass_bos_validation,
    isOnlyFactured,
    isMissingBOSInfos: !opportunity.is_ready_to_bos,
    isBOSAssignmentAvailable,
    isWithinBOSAssignmentState,
  }
}

export const parseInterrogation = (interrogation: InterrogationResponse): Interrogation => ({
  id: interrogation.id,
  finspotId: interrogation.finspot_id,
  bankContactName: interrogation.bank_contact_name,
  bankGroup: interrogation.bank_group,
  bankResponseStatus: interrogation.bank_response?.status ?? null,
  bankResponseCreatedAt: interrogation.bank_response?.created_at ?? null,
  isBankResponseExpected: interrogation.bank_response === null && interrogation.stage !== 'dropped',
  stage: interrogation.stage,
  loanAmount: interrogation.requested_amount,
  loanDuration: interrogation.requested_duration,
  loanRate: interrogation.requested_rate,
  createdAt: interrogation.finspot_created_at,
  updatedAt: interrogation.finspot_updated_at,
  sentAt: interrogation.sent_at,
})

export const parseInterrogations = (interrogations?: InterrogationResponse[]): Interrogation[] => {
  return interrogations?.map(parseInterrogation) || []
}

export const parseMandateSignatures = (opp: OpportunityResponse): Signature[] | null => {
  const signatures = opp.mandate?.signatures

  if (!signatures) {
    return null
  }

  return signatures.map(signature => {
    if (signature.is_representative) {
      return {
        representative: { email: signature.email, signedAt: signature.signed_at },
      }
    }

    return {
      mortgagor: { email: signature.email, signedAt: signature.signed_at },
    }
  })
}
