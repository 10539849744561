import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useForm } from '@tanstack/react-form'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { textAreaProps } from 'helpers/tanstackForm'
import { BosAssignment } from 'lib/oleenApi/services/mortgageOpportunity/opportunityBosAssignmentsService'
import React from 'react'
import { Button } from 'ui/buttons/button'
import { RadioGroup } from 'ui/buttons/radioGroup'
import { TanstackFormInput } from 'ui/misc/tanstackFormInput'
import { TextArea } from 'ui/textareas/textArea'
import { I18NPath, t } from 'utils/i18n'
import { z } from 'zod'

interface BOSFormProps {
  bosAssignment?: BosAssignment
  action: (bosAssignment: BosAssignment) => void
  onCancel: () => void
}

enum Fields {
  allowToContactClient = 'allowToContactClient',
  loanOfferChecked = 'loanOfferChecked',
  bankContactGiven = 'bankContactGiven',
  notaryContactGiven = 'notaryContactGiven',
  additionalInformation = 'additionalInformation',
}

export const BOSAssignmentInstructionForm: React.FC<BOSFormProps> = ({ bosAssignment, action, onCancel }) => {
  const { Field, Subscribe, handleSubmit } = useForm({
    defaultValues: {
      allowToContactClient: bosAssignment?.allowToContactClient,
      loanOfferChecked: bosAssignment?.loanOfferChecked,
      bankContactGiven: bosAssignment?.bankContactGiven,
      notaryContactGiven: bosAssignment?.notaryContactGiven,
      // NOTE: textarea value attribute should never be null
      additionalInformation: bosAssignment?.additionalInformation || '',
    },
    validatorAdapter: zodValidator(),
    onSubmit: ({ value }) => {
      return action(value as BosAssignment)
    },
  })

  return (
    <form className="flex flex-col gap-4">
      {Object.values(Fields)
        .slice(0, -1)
        .map(field_name => (
          <Field
            key={field_name}
            name={field_name}
            validators={{ onChange: z.boolean(), onMount: z.boolean({ message: ' ' }) }}
          >
            {field => (
              <TanstackFormInput
                className="flex flex-col w-full gap-1"
                label={t(`opportunity.bos_assignment_modal.steps.instructions.${field_name}` as I18NPath)}
                field={field}
              >
                <RadioGroup
                  name={field_name}
                  className="flex gap-6"
                  value={field.state.value?.toString()}
                  onChange={value => field.handleChange(JSON.parse(value))}
                  options={[
                    { value: 'true', label: t('common.yes') },
                    { value: 'false', label: t('common.no') },
                  ]}
                />
              </TanstackFormInput>
            )}
          </Field>
        ))}
      <Field name={Fields.additionalInformation}>
        {field => (
          <TanstackFormInput
            className="flex flex-col w-full gap-1"
            label={t('opportunity.bos_assignment_modal.steps.instructions.additionalInformation')}
            field={field}
          >
            <TextArea
              placeholder={t('opportunity.bos_assignment_modal.steps.instructions.additionalInformationPlaceholder')}
              {...textAreaProps(field, 'textArea--small h-32')}
            />
          </TanstackFormInput>
        )}
      </Field>

      <div className="flex justify-between">
        <Button className="button--medium button--secondary button--outline" onClick={onCancel}>
          {t('opportunity.bos_assignment_modal.previous')}
        </Button>
        <Subscribe selector={state => [state.isFieldsValid, state.isSubmitting]}>
          {([allowSubmit, isSubmitting]) => (
            <Button
              className="button--medium button--primary button--solid"
              onClick={handleSubmit}
              disabled={!allowSubmit || isSubmitting}
            >
              {t('opportunity.bos_assignment_modal.next')}
              <ChevronRightIcon className="w-5 h-5" />
            </Button>
          )}
        </Subscribe>
      </div>
    </form>
  )
}
