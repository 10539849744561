import React, { ReactNode } from 'react'

export interface IKanbanProps<T> {
  dataMap: Record<string, T[]>
  renderDataItems: (item: T) => ReactNode
  renderHeader: (header: string) => ReactNode
}

export const Kanban: <T>(props: IKanbanProps<T> & { children?: ReactNode }) => JSX.Element = ({
  dataMap,
  renderDataItems,
  renderHeader,
}) => {
  return (
    <div className="flex grow h-full relative">
      <div className="absolute inset-0 flex gap-x-4 w-full overflow-x-auto snap-x snap-mandatory">
        {Object.entries(dataMap).map(([columnTitle, data], index) => (
          <React.Fragment key={index}>
            <div className="column">
              <div className="p-4">{renderHeader(columnTitle)}</div>
              <div className="max-w-full flex flex-col grow p-4 pb-4 space-y-4">{data.map(renderDataItems)}</div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
