import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

export type BannerProps = PropsWithChildren<{
  id?: string
  className?: string
}>

export const Banner: React.FC<BannerProps> = ({ id, className, children }) => {
  return (
    <div id={id} className={classNames('alert rounded-none', className)}>
      {children}
    </div>
  )
}
