import { errorHandlerMode } from 'helpers/errorHandler'
import { OPPORTUNITY_FIELDS, OPPORTUNITY_SOURCE_OPTIONS } from 'helpers/opportunity'
import React, { Suspense, useMemo } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { FormInput } from 'ui/forms/formInput'
import { LabelledCardVerticalBody } from 'ui/legacy/molecules/card/card.styled'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { PlusIcon } from '@heroicons/react/20/solid'
import { DisabledSourceType, Opportunity } from 'lib/oleenApi'
import { CLICKS } from 'helpers/tracking'
import { useNavigate } from 'react-router-dom'
import { DropdownOption } from 'ui/dropdowns/dropdown'
import { EMPTY_SIGN } from 'helpers/format'

interface ISourceCardProps {
  opportunity: Opportunity
  className?: string
}

export const SourceCard: React.FC<ISourceCardProps> = ({ opportunity, className }) => {
  const navigate = useNavigate()
  const isSourceTypeDisabled = useMemo(() => {
    const disabledSourceTypes: string[] = Object.values(DisabledSourceType)
    return opportunity.sourceType && disabledSourceTypes.includes(opportunity.sourceType)
  }, [opportunity.sourceType])

  const isLeadContributorDisabled = useMemo(() => {
    return isSourceTypeDisabled || opportunity.yuccanleadId !== null
  }, [isSourceTypeDisabled, opportunity.yuccanleadId])

  const defaultOptions: DropdownOption[][] | undefined = opportunity.leadContributorId
    ? [
        [
          {
            label: opportunity.leadContributorName || EMPTY_SIGN,
            value: opportunity.leadContributorId,
            type: 'text',
          },
        ],
      ]
    : undefined

  return (
    <LabelledCard icon={CARD_ICON.UsersIcon} title={t('opportunity.source_title')} className={className}>
      <LabelledCardVerticalBody>
        <div className="space-y-3 md:min-h-[112px]">
          <FormInput
            type="select"
            selectClassName="selectInput--small"
            dropdownClassName="w-full z-10"
            name={OPPORTUNITY_FIELDS.SOURCE_TYPE}
            defaultValue={opportunity.sourceType}
            options={[OPPORTUNITY_SOURCE_OPTIONS]}
            disabled={isSourceTypeDisabled ?? false}
          />
          {!isSourceTypeDisabled && (
            <>
              <FormInput
                type="leadContributorSelect"
                name={OPPORTUNITY_FIELDS.SOURCE_LEAD_CONTRIBUTOR}
                defaultValue={opportunity.leadContributorId}
                disabled={isLeadContributorDisabled}
                defaultOptions={defaultOptions}
              />
              {opportunity.yuccanleadId === null && (
                <>
                  <Button
                    className="button--outline button--primary button--small"
                    tracking={[CLICKS.opportunityAddLeadContributor]}
                    onClick={() => navigate('new-lead-contributor')}
                  >
                    <PlusIcon className="w-4 h-4" />
                    {t('opportunity.source_type.create_lead_contributor')}
                  </Button>
                  <FormInput
                    name={OPPORTUNITY_FIELDS.SOURCE_AMOUNT}
                    textInputClassName="textInput--small"
                    label={t('opportunity.source_type.lead_contributor_fees')}
                    validations={[{ type: errorHandlerMode.NON_NULLABLE }, { type: errorHandlerMode.NUMBER }]}
                    defaultValue={opportunity.sourceAmountPaid?.toString()}
                    disabled={!opportunity.projectId}
                  />
                </>
              )}
            </>
          )}
        </div>
      </LabelledCardVerticalBody>
    </LabelledCard>
  )
}
