import { EMPTY_SIGN, formatFullName } from 'helpers/format'
import { useLeadContributorsInfiniteQuery } from 'helpers/queries'
import { useDebouncedState } from 'hooks/debouncedState'
import React, { useMemo } from 'react'
import { DropdownOption, DropdownOptionValue, DropdownSection } from 'ui/dropdowns/dropdown'
import { SelectInput } from 'ui/dropdowns/selectInput'

type LeadContributorSelectInputProps = {
  value: string | null
  onChange: (value: DropdownOptionValue, data: unknown) => void
  onBlur?: () => void
  className?: string
  disabled?: boolean
  defaultOptions?: DropdownSection[]
}

export const LeadContributorSelectInput: React.FC<LeadContributorSelectInputProps> = ({
  className,
  value,
  onChange,
  onBlur,
  disabled,
  defaultOptions,
}) => {
  const [search, setSearch] = useDebouncedState('', 200)
  const query = useLeadContributorsInfiniteQuery(search)

  const options: DropdownOption[] = useMemo(
    () =>
      query?.data?.pages
        .flatMap(page => page.items)
        .map(leadContributor => ({
          value: leadContributor.id,
          label: formatFullName(
            leadContributor.firstName,
            leadContributor.lastName,
            leadContributor.email ?? EMPTY_SIGN
          ),
          type: 'text',
          data: leadContributor,
        })) ?? [],
    [query?.data?.pages]
  )

  return (
    <SelectInput
      options={[...(defaultOptions ?? []), options]}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onDropdownBottomReached={query.fetchNextPage}
      className={`${className} selectInput--small`}
      dropdownClassName="w-full !max-h-40"
      isSearchable={true}
      onSearch={setSearch}
      disabled={disabled}
    />
  )
}
