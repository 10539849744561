import { CalendarDaysIcon, RocketLaunchIcon } from '@heroicons/react/20/solid'
import {
  BanknotesIcon,
  ChatBubbleBottomCenterTextIcon,
  ClockIcon,
  FolderOpenIcon,
  HomeIcon,
  IdentificationIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/solid'
import * as React from 'react'

import { CardContainer, LabelledCardHead as Head } from './card.styled'

interface ILabelledCard {
  children: React.ReactNode
  title: string
  fullHeight?: boolean
  icon: JSX.Element
  headerRightElement?: JSX.Element
  className?: string
}

const icon = (Component: HeroIcon): JSX.Element => <Component className="text-gray-500 inline-block w-5 h-5" />

export const CARD_ICON = {
  IdentificationIcon: icon(IdentificationIcon),
  UsersIcon: icon(UsersIcon),
  HomeIcon: icon(HomeIcon),
  AnnotationIcon: icon(ChatBubbleBottomCenterTextIcon),
  FolderOpenIcon: icon(FolderOpenIcon),
  UserIcon: icon(UserIcon),
  ClockIcon: icon(ClockIcon),
  BankNotesIcon: icon(BanknotesIcon),
  RocketLaunchIcon: icon(RocketLaunchIcon),
  CalendarDaysIcon: icon(CalendarDaysIcon),
}

const LabelledCard: React.FC<ILabelledCard> = ({
  children,
  title,
  icon,
  headerRightElement,
  fullHeight,
  className,
}) => {
  return (
    <CardContainer className={`h-full rounded-lg ${className}`}>
      <Head>
        <div>
          {icon}
          <span className="ml-[10px]">{title}</span>
        </div>
        {headerRightElement}
      </Head>
      {children}
    </CardContainer>
  )
}

export default LabelledCard
